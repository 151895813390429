import React, { useEffect, useState } from 'react';
import sierraVertical from '../../images/verticals/Sierra Bell.png'
import valkyrieVertical from '../../images/verticals/Project Valkyrie.png'
import shenanigansVertical from '../../images/verticals/Shenanigans.png'
import johnVertical from '../../images/verticals/John Coday.png'
import gardenVertical from '../../images/verticals/GardenWolf.png'
import dizzyVertical from '../../images/verticals/DizzyDummyBoy.png'
import zmVertical from '../../images/verticals/ZM_Art.png'
import sierrabell from '../../images/thumbnails/sierrabell.png'
import valkyrie from '../../images/thumbnails/valkyrie.png'
import dungeon from '../../images/thumbnails/dungeon.jpg'
import kaikaku from '../../images/thumbnails/kaikaku.png'
import shenanigans from '../../images/thumbnails/shenanigans.png'
import dmn7 from '../../images/thumbnails/dmn7.png'
import labrat from '../../images/thumbnails/labrat.png'
import johncrandom from '../../images/thumbnails/johncrandom.png'
import emblem from '../../images/ZM.png'
import {Helmet} from "react-helmet";

const about_content = {
  margin: '80px'
}

const emblem_style = {
  width: '20%'
}

function ZM(){
  return(
    <div style={about_content}>
      <Helmet>
        <title>ZM_Art</title>
        <meta name="description" content="ZM_Art" />
      </Helmet>
      <h1>ZM_Art</h1>
      <h2>
        <img src={emblem} alt="logo" style={emblem_style} />
      </h2>
      <h2>
        Zach is a Comic Creator, creating Project: Valkyrie as well as working a ton on Content Creation
      </h2>
      <h1>ZM's Comics</h1>
      <div className="blocks">
        <a href="/comics/valkyrie"><img src={valkyrie} alt="" class="thumbnail" /></a>
        <a href="/comics/dungeon"><img src={dungeon} alt="" class="thumbnail" /></a>
      </div>
    </div>
  )
}

export default ZM;