import React, { ChangeEvent, useEffect, useMemo, useState, useRef } from 'react';
import {
  BrowserRouter as Router,
  Link,
  Route,
  Routes,
  useParams,
  redirect,
  useNavigate
} from "react-router-dom";
import DatePicker from "react-datepicker";
import Select from 'react-select'
import '../../stylesheets/dashboardEditing.css'
import axios from 'axios'


function EditComicEpisode(){
  const [episode, setEpisode] = useState({});
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState('')
  const [title, setTitle] = useState('')
  const [format, setFormat] = useState('')
  const [premium, setPremium] = useState(false)
  const [dateAvailable, setDateAvailable] = useState()
  const [hidden, setHidden] = useState(false)
  const [episodeNumber, setEpisodeNumber] = useState(0)
  const [seasonNumber, setSeasonNumber] = useState(0)

  const { series, episodeId } = useParams()

  const navigate = useNavigate();

  const dragItem = useRef();
  const dragOverItem = useRef();
  const [list, setList] = useState([]);

  const [newPages, setNewPages] = useState()
  const [thumbnail, setThumbnail] = useState()

  const handleChangeFile = (e) => {
    const img = {
      preview: URL.createObjectURL(e.target.files[0]),
      data: e.target.files[0],
    };
    setNewPages(e.target.files[0])
  }

  const handleThumbnailFile = (e) => {
    setThumbnail(e.target.files[0])
  }
  
  const uploadPages = () => {
    const formData = new FormData();
    formData.append('file', newPages);
    formData.append('episodeID', episodeId)
    const data = JSON.stringify({
      file : newPages,
      episodeID : episodeId
    })

    const request = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json; charset=utf-8' },
      url: `${process.env.REACT_APP_BACKEND_SERVER}/comic/uploadfiles`,
      data: formData
    }

    fetch(`${process.env.REACT_APP_BACKEND_SERVER}/comic/uploadfiles`,
			{
				method: 'POST',
				body: formData,
			}
		)
    .then((result) => {
      setLoading(true)
      reloadComicData()
    })
  }
 
  const dragStart = (e, position) => {
    dragItem.current = position;
  };
 
  const dragEnter = (e, position) => {
    dragOverItem.current = position;
  };
 
  const drop = (e) => {
    const copyListItems = [...list];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setList(copyListItems);
  };

  const getResultsData = async () => {
    if(isLoading){  
      const response = await fetch(`${process.env.REACT_APP_BACKEND_SERVER}/comic/byEpisode/${series}/${episodeId}`)
      .then((response) => response.json({}))
      .then((response) => {
        setEpisode(response)
        setList(response.Pages)
        setTitle(response.Title)
        setPremium(response.Premium)
        setDateAvailable(new Date(response.DateAvailable))
        setHidden(response.Hidden)
        setEpisodeNumber(response.Episode)
        setSeasonNumber(response.Season)
        setFormat(response.Format)
        setThumbnail(response.Thumbnail)
      })
      .catch((err) => {
        setError(err)
      });
      
    }
  };

  const removePage = (e) => {
    const a = list
    const newArray = a.splice(e, 1);
    const formData = new FormData();
    formData.append('episodeID', episodeId)
    const data = {
      episodeId: episodeId,
      series: series,
      title: title,
      premium: premium,
      format: format,
      hidden: hidden,
      dateAvailable: dateAvailable,
      episodeNumber: episodeNumber,
      seasonNumber: seasonNumber,
      pages: newArray
    }
    const request = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json; charset=utf-8' },
      url: `${process.env.REACT_APP_BACKEND_SERVER}/comic/updateComicEp`,
      data: data
    }
    axios(request)
    .then((result) => {
      const r = result
    })
  }

  const reloadComicData = () => {
    getResultsData();
    if(episode && isLoading == true){
      reloadComicData()
      setLoading(false)
    }else{
      reloadComicData()
    }
  }

  useEffect(() => {
    getResultsData();
    if(episode && isLoading == true){
      setLoading(false)
    }
  });

  const uploadThumbnail = async () => {
    const formData = new FormData();
    formData.append('file', thumbnail);
    formData.append('episodeID', episodeId)

    fetch(`${process.env.REACT_APP_BACKEND_SERVER}/comic/uploadThumbnail`,
			{
				method: 'POST',
				body: formData,
			}
		)
    .then((result) => {
      setLoading(true)
      reloadComicData()
    })
  }

  const submitForm = async () => {
    const formData = new FormData();
    formData.append('episodeID', episodeId)
    const data = {
      episodeId: episodeId,
      series: series,
      title: title,
      premium: premium,
      format: format,
      hidden: hidden,
      dateAvailable: dateAvailable,
      episodeNumber: episodeNumber,
      seasonNumber: seasonNumber,
      pages: list
    }

    const request = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json; charset=utf-8' },
      url: `${process.env.REACT_APP_BACKEND_SERVER}/comic/updateComicEp`,
      data: data
    }
    
    axios(request)
    .then((result) => {
      const r = result
      navigate(`/dashboard/comics/${series}`)
    })
  }

  const options = [
    { value: 'horizontal', label: 'horizontal' },
    { value: 'vertical', label: 'vertical' }
  ]

  if (error) return `${error}`
  if (isLoading === true) return 'Loading...'
  if(true){
    return (
      <>
      <div class="textArea">
        <div>Title</div>
        <input type="text" onChange={(e) => setTitle(e.target.value)} value={title} />
        <div>Premium</div>
        <input type="checkbox" onChange={(e) => setPremium(!premium)} class="checkbox" checked={premium} />
        <div>Format</div>
        <select onChange={(e) => setFormat(e.target.value)} value={format}>
          <option value="horizontal">Horizontal</option>
          <option value="vertical">Vertical</option>
        </select>
        <div>Hidden</div>
        <input type="checkbox" class="checkbox" onChange={(e) => setHidden(!hidden)} checked={hidden} />
        <div>Episode Number</div>
        <input type="number" onChange={(e) => setEpisodeNumber(e.target.value)} value={episodeNumber} />
        <div>Season Number</div>
        <input type="number" onChange={(e) => setSeasonNumber(e.target.value)} value={seasonNumber} />
        <br />
        <br />
        <h1>Add Thumbnail</h1>
        <img src={`https://storage.googleapis.com/mythosmakers_storage/${thumbnail}`}></img>
        <input type="file" name="file" files={thumbnail} onChange={handleThumbnailFile}></input>
        <button onClick={uploadThumbnail}>Upload</button>
        <h1>Add Pages</h1>
        <input  type="file" name="file" files={newPages} onChange={handleChangeFile}/>
        <button onClick={uploadPages}>Upload</button>
      </div>
      <div class="editSection">
        {
        list&&
        list.map((item, index) => (
          <div style={{margin:'20px', textAlign:'center', fontSize:'25px'}}
            onDragStart={(e) => dragStart(e, index)}
            onDragEnter={(e) => dragEnter(e, index)}
            onDragEnd={drop}
            key={index}
            draggable>
              <img src={`https://storage.googleapis.com/mythosmakers_storage/${item}`} alt="" class="horizontalPageEdit" />
              <br />
              <button onClick={() => removePage(index)}>
                Delete
              </button>
          </div>
          ))}
          
        </div>
        <button onClick={submitForm}> Submit </button>
      </>
    );
  }
  
};

export default EditComicEpisode;