import React, { useEffect, useState } from 'react';
import sierraVertical from '../../images/verticals/Sierra Bell.png'
import valkyrieVertical from '../../images/verticals/Project Valkyrie.png'
import shenanigansVertical from '../../images/verticals/Shenanigans.png'
import johnVertical from '../../images/verticals/John Coday.png'
import gardenVertical from '../../images/verticals/GardenWolf.png'
import dizzyVertical from '../../images/verticals/DizzyDummyBoy.png'
import zmVertical from '../../images/verticals/ZM_Art.png'
import sierrabell from '../../images/thumbnails/sierrabell.png'
import valkyrie from '../../images/thumbnails/valkyrie.png'
import kaikaku from '../../images/thumbnails/kaikaku.png'
import shenanigans from '../../images/thumbnails/shenanigans.png'
import dmn7 from '../../images/thumbnails/dmn7.png'
import labrat from '../../images/thumbnails/labrat.png'
import johncrandom from '../../images/thumbnails/johncrandom.png'
import emblem from '../../images/Emblem 2.2.png'
import {Helmet} from "react-helmet";

const about_content = {
  margin: '80px'
}

const emblem_style = {
  width: '20%'
}

function JohnCoday(){
  return(
    <div style={about_content}>
      <Helmet>
        <title>John Coday</title>
        <meta name="description" content="John Coday" />
      </Helmet>
      <h1>John Coday</h1>
      <h2>
        <img src={emblem} alt="logo" style={emblem_style} />
      </h2>
      <h2>John Creates several webcomics and games including Sierra Bell: The Magic’s Curse and The Tale of Kaikaku, as well as created games including DMN7, Shenanigans, and LabRat. John also creates funny VRChat avatars and shows them to people on VRChat as well as other random forms of content on YouTube.</h2>
      <h1>John Coday Franchises</h1>
      <div className='blocks'>
        <a href="/comics/sierra-bell">
          <img src={sierraVertical} className="vertical-thumbnail" alt="" />
        </a>
        <a href="/games/shenanigans">
          <img src={shenanigansVertical} className="vertical-thumbnail" alt="" />
        </a>
      </div>
      <h1>
        John Coday Comics
      </h1>
      <div className='blocks'>
        <a href="/comics/sierra-bell"><img src={sierrabell} alt="" class="thumbnail" /></a>
        <a href="/comics/kaikaku"><img src={kaikaku} alt="" class="thumbnail" /></a>
      </div>
      <h1>
        John Coday Games
      </h1>
      <div className='blocks'>
        <a href="/games/shenanigans"><img src={shenanigans} alt="" class="thumbnail" /></a>
        <a href="/games/dmn7"><img src={dmn7} alt="" class="thumbnail" /></a>
        <a href="/games/labrat"><img src={labrat} alt="" class="thumbnail" /></a>
      </div>
      <h1>
        John Coday Videos
      </h1>
      <div className='blocks'>
        <a href="/videos/johncoday"><img src={johncrandom} alt="" class="thumbnail" /></a>
      </div>
    </div>
  )
}

export default JohnCoday