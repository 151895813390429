import React, { useEffect, useState } from 'react';

const about_content = {
  margin: '80px'
}

function Contact() {
  return(
    <div style={about_content}>
      <h1>
        Contact Us
      </h1>
      <h2>
        Contact us by email at - mail@mythosmakers.com
      </h2>
      <h2>
        We will get to you as soon as possible!!
      </h2>
      {/* <h1>
      Contact Us
      </h1>
      <h2>
      Please Read Below Before Emailing!
      </h2>
      <p>
      We get emails a lot even though we are not very big yet so this is to begin filtering emails and also help guide some people on sending a good informative email so that I could potentially work with them. This is not me being mean, it is us trying to filter our inbox!
      </p>
      <p>
      When sending an email, then do these things…
      <ul>
      <li>
      Format your email in a professional way, explaining who you are, what you do, and why you are emailing with as much detail as possible.
      </li>
      <li>
      Provide links and info about you so that we can research and find your info (Just a name will not work, has to be links)
      </li>
      </ul>
      </p>
      <p>
      Are you a company who wishes to help with game, comic, video, or other production promotion? Then…
      <ul>
      <li>
      Provide us with details on your company or individual name and what you do to promote products.
      </li>
      <li>
      Provide a link to your website or closest applicable place to look at your company or individual info.
      </li>
      <li>
      Provide links to previous promotions that you have done. If you do not provide this, you will be ignored.
      </li>
      <li>
      If you do not receive a message from me within 5 days, you either did not comply with the above or we were not interested.
      </li>
      </ul>
      </p>
      <p>
      Are you an individual wishing to join the team? Below is info for each position that we may or may not need…
      <ul>
      <li>
      Are you a
      <span style={{fontWeight: 'bolder', marginRight: '5px', marginLeft: '5px'}}>voice actor?</span>
      Then do not email, instead, wait for auditions to happen either here on MythosMakers.com or JohnCoday.com, individual pages on the website, or on my twitter. This is due to a large number of voice actors being in my contacts and so we want to keep a good playing field when it comes to VAs.
      </li>
      <li>
      Are you an
      <span style={{fontWeight: 'bolder', marginRight: '5px', marginLeft: '5px'}}>artist?</span>
      The with your email, send your Username or Full Name and provide a portfolio link or relevant link in your message.
      </li>
      <li>
      Are you a
      <span style={{fontWeight: 'bolder', marginRight: '5px', marginLeft: '5px'}}>programmer?</span>
      Then with your email, send your GitHub link or a portfolio link with projects you have worked on previously. Preferably with C#, C++, Python, Unity, Unreal Engine, GoDot, and GameMaker Studio 2.
      </li>
      <li>
      Are you a
      <span style={{fontWeight: 'bolder', marginRight: '5px', marginLeft: '5px'}}>musician?</span>
      Then with your email, send your Username or Full Name and provide a portfolio link or relevant link in your message.
      </li>
      <li>
      For anything else Email with any relevant link to works you have done as well as your name or username.
      </li>
      </ul>
      </p>
      <p>
      Are you emailing regarding sponsorship of one of our YouTube Channels, Twitch Channels, or other media that we make? Then…
      <ul>
      <li>
      Please email and provide a description of your product, a link to your website and information regarding the sponsorship.
      </li>
      </ul>
      </p>
      <p>
      Are you emailing with an idea that you would want to see done or work on? Then please keep in mind that we try to keep credit where credit is due whenever it comes to certain things. With that said there are a few conditions on certain ideas that we can and can’t do.
      <ul>
      <li>
      Are you giving a webcomic idea? Then don’t send it. Legally we cannot do that and also we are working on several webcomics already. we would encourage you to look into making it yourself.
      </li>
      <li>
      Are you giving a video game idea? Then do not email unless you are willing to make an early test build and concepts of the game and potentially be hired to head the project. For legal reasons, we cannot take a game idea from someone unless we do legal jargon or allow the individual to be on the project, which more than likely we will not do.
      </li>
      </ul>
      </p>
      <p>
      Are you emailing regarding AI, Crypto Currency, or NFTs, F*** Off
      </p>
      <p>
      Are you a company or individual that we have contacted before for any of the above or any other subject matter? Then don’t email me, we already have you in contact and will reach out when applicable.
      </p> */}
    </div>
  )
}

export default Contact;