import React, { useEffect, useMemo, useState, useRef } from 'react';
import { Carousel } from 'react-responsive-carousel';
import {
  BrowserRouter as Router,
  Link,
  Route,
  Routes,
  useParams,
  useNavigate
} from "react-router-dom";
import {Helmet} from "react-helmet";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Select from 'react-select'

import '../../stylesheets/comic.css'

function ComicEpisode(){
  const [episode, setEpisode] = useState({});
  const [allEpisodes, setAllEpisodes] = useState({});
  const [episodeNames, setEpisodeNames] = useState([]);
  const [defaultSelect, setDefaultSelect] = useState(0)
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(false)

  var defaultOption = null;

  const navigate = useNavigate();

  const { series, episodeId } = useParams()

  const getResultsData = async () => {
    if(isLoading){  
      const allEp = await fetch(`${process.env.REACT_APP_BACKEND_SERVER}/comic/all/${series}`)
      .then((response) => response.json({}))
      

      const response = await fetch(`${process.env.REACT_APP_BACKEND_SERVER}/comic/byEpisode/${series}/${episodeId}`)
      .then((response) => response.json({}))
      .then((response) => setEpisode(response))
      .then((response) => setAllEpisodes(allEp))
      .then((n) => setLoading(false))
      .catch((err) => {
        setError(true)
      });
    }
  };

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  var settings = {
    dots: true,
    infinite: false,
    arrows: true,
    lazyLoad: true,
    accessibility: true
  };

  var mobileSettings = {
    dots: false,
    infinite: false,
    arrows: false,
    lazyLoad: true,
    accessibility: true
  };

  useEffect(() => {
    getResultsData();
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });

  const episodeSelect = (epID) => {
    console.log(epID["value"])
    console.log(`/comics/${series}/${epID["value"]}`)
    navigate(`/comics/${series}/${epID["value"]}`)
    setLoading(true)
  }

  const getDefaultSelect = (epID) => {
    let newOptions = getEpisodeNames()

    for (let i = 0; i < newOptions.length; i++){
      if(newOptions[i]["value"] == episodeId){
        return(newOptions[i])
      }
    }
  }

  const getEpisodeNames = () => {
    let newOptions = []
    for (let i = 0; i < allEpisodes.length; i++){
      newOptions.push({value: allEpisodes[i]["ID"], label: "S:" + allEpisodes[i]["Season"] + "E:" + allEpisodes[i]["Episode"] + " - " + allEpisodes[i]["Title"]})
    }
    return(newOptions)
  }

  const selectSection = () => {
    return(
      <Select options={getEpisodeNames()} onChange={episodeSelect} defaultValue={getDefaultSelect(episodeId)} styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          backgroundColor: '#3d3d3d',
          color: "white",
          border: 0,
        }),
        option: (base, state) => ({
          ...base,
          color: 'white',
          backgroundColor: state.isSelected ? '#2b2b2b' : '#3d3d3d',
          border: 0
        }),
        placeholder: (base, state) => ({
          ...base,
          color: "white"
        }),
        singleValue: (base, state) => ({
          ...base,
          color: "white"
        }),
        menuList: (base, state) => ({
          ...base,
          color: 'white',
          backgroundColor: '#3d3d3d',
          border: 0
        }),
      }}/>
    )
  }

  const getNextOrPreviousEpisode = (option) => {
    let newOptions = getEpisodeNames()
    let result = ""
    for (let i = 0; i < newOptions.length; i++){
      try{
        if(newOptions[i]["value"] == episodeId){
          result = newOptions[i + option]["value"]
        }
      } catch(error){
        return(<a href={`/comics/${series}`}>End Of Current Chapters</a>)
      }
    }
    if(option === -1){
      return(
        <a href={`/comics/${series}/${result}`}>Previous Episode</a>
      )
    }else{
      return(
        <a href={`/comics/${series}/${result}`}>Next Episode</a>
      )
    }
  }

  if (isLoading === true) return 'Loading...'

  if (error) return 'Error has occurred'
  if (episode["Format"] == "horizontal"){
    if (windowSize[0] > 900){
      return (
        <div class="comicContainer" >
          <Helmet>
            <title>Mythos Makers - {episode["Title"]}</title>
            <meta name="description" content={episode["Title"]} />
          </Helmet>
          
          <div class="selectContainer">
            {getNextOrPreviousEpisode(-1)}
            <div class="selectItem">
              {selectSection()}
            </div>
            {getNextOrPreviousEpisode(1)}
          </div>
          <br />
          <div class="pagesContainer">
          <Slider {...settings} className="comicSlider" >
          {Object.keys(episode["Pages"]).map((key, i) => (
            <div>
              <div class="pageContainer">
                <img src={`https://storage.googleapis.com/mythosmakers_storage/${episode["Pages"][key]}`} alt="" class="horizontalPage" />
              </div>
            </div>
          ))}
          </Slider></div>
        </div>
      )
    }else{
      return (
        <div class="comicContainer" >
          <Helmet>
            <title>Mythos Makers - {episode["Title"]}</title>
            <meta name="description" content={episode["Title"]} />
          </Helmet>
          <div class="selectContainer">
          {selectSection()}
          </div>
          <br />
          <div class="pagesContainer">
            <Slider {...mobileSettings} className="comicSlider" >
            {Object.keys(episode["Pages"]).map((key, i) => (
              <div class="pageContainer">
                <img src={`https://storage.googleapis.com/mythosmakers_storage/${episode["Pages"][key]}`} alt="" class="horizontalPage" />
              </div>
            ))}
          </Slider></div>
        </div>
      )
    }
  }else{
    return(
      <div class="comicContainer" >
        <Helmet>
          <title>Mythos Makers - {episode["Title"]}</title>
          <meta name="description" content={episode["Title"]} />
        </Helmet>
        <div class="selectContainer">
        {selectSection()}
          </div>
        <div class="pagesContainer">
        <img src={`https://storage.googleapis.com/mythosmakers_storage/${episode["Pages"][0]}`} alt="" class="verticalPage" />
        </div>
      </div>
    )
  }
  
}

export default ComicEpisode;