import '../../stylesheets/creatorDashboard.css'
import React, { useEffect, useState } from 'react';
import Cookies from "universal-cookie";
import { BrowserRouter, redirect, Route, Switch, useNavigate } from 'react-router-dom';
import sierrabell from '../../images/thumbnails/sierrabell.png'
import valkyrie from '../../images/thumbnails/valkyrie.png'
import kaikaku from '../../images/thumbnails/kaikaku.png'
import johncoday from '../../images/thumbnails/johncrandom.png'
import frog from '../../images/thumbnails/frog.png'
import showerbrewcrew from '../../images/thumbnails/showerbrewcrew.png'
import rain from '../../images/thumbnails/rain.png'
import shenanigans from '../../images/thumbnails/shenanigans.png'
import dmn7 from '../../images/thumbnails/dmn7.png'
import labrat from '../../images/thumbnails/labrat.png'
import dungeon from '../../images/thumbnails/dungeon.jpg'


const cookies = new Cookies();


function CreatorDashboard(){

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const userID = cookies.get("currentUserID")
  const userName = cookies.get("currentUsername")
  const [user, setUser] = useState({}) 
  const [isCreator, setIsCreator] = useState(false)
  const [loadedUserDate, setLoadedUserDate] = useState(false);

  const getUser = async (theUserId) => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_SERVER}/loginapi/getuser/${theUserId}`)
    .then(
      (response) => response.json({})
    )
    setUser(response)
    setIsCreator(user.isCreator)
    setIsLoggedIn(true)
    setLoadedUserDate(true)
  }

  const getComic = (comicID) => {
    if(comicID == "sierra-bell"){
      return(<a href="dashboard/comics/sierra-bell"><img src={sierrabell} alt="" class="thumbnail" /></a>)
    }
    if(comicID == "kaikaku"){
      return(<a href="dashboard/comics/kaikaku"><img src={kaikaku} alt="" class="thumbnail" /></a>)
    }
    if(comicID == "valkyrie"){
      return(<a href="dashboard/comics/valkyrie"><img src={valkyrie} alt="" class="thumbnail" /></a>)
    }
    if(comicID == "dungeon"){
      return(<a href="dashboard/comics/dungeon"><img src={dungeon} alt="" class="thumbnail" /></a>)
    }
    return(
      <>
      </>
    )
  }

  const getVideo = (videoID) => {
    if(videoID == "johncoday"){
      return(<a href="dashboard/videos/johncoday"><img src={johncoday} alt="" class="thumbnail" /></a>)
    }
    if(videoID == "frenchfrog"){
      return(<a href="dashboard/videos/frenchfrog"><img src={frog} alt="" class="thumbnail" /></a>)
    }
    if(videoID == "showerbrewcrew"){
      return(<a href="dashboard/videos/showerbrewcrew"><img src={showerbrewcrew} alt="" class="thumbnail" /></a>)
    }
    if(videoID == "mrraingaming"){
      return(<a href="dashboard/videos/mrraingaming"><img src={rain} alt="" class="thumbnail" /></a>)
    }
    return(
      <>
      </>
    )
  }

  useEffect(() => {
    if(userID != null){
      getUser(userID)
    }
    if(user.isCreator == false){
      redirect("/")
    }
  }, [])

  const getUserComics = () => {
    if(user.Creations["comics"].length > 0){
      return(
        <div>
          <h2>Comics</h2>
          <div className="blocks">{user.Creations["comics"].map(thing =>(
            <div>{getComic(thing)}</div>
          ))}</div>
          <h2>Videos</h2>
          <div className="blocks">{user.Creations["videos"].map(thing =>(
            <div>{getVideo(thing)}</div>
          ))}</div>
        </div>
      )
    }
  }

  if(loadedUserDate == false){
    return("Loading")
  }
  return(
    <div className="dashboard-setting">
      <h1>
        Creator Dashboard
      </h1>
      <h1>{user.name}</h1>
      {getUserComics()}
    </div>
  )
}

export default CreatorDashboard;