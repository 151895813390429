import React, { useEffect, useState } from 'react';

const about_content = {
  margin: '80px'
}

function Ads() {
  return(
    <div style={about_content}>
      <h1>
      About Ads
      </h1>
      <p>
      We have recently implemented Ads into Mythos Makers, however, we have taken a lot of time to make sure that the ads served are appropriate for the user.
      </p>
      <p>
      We are currently trialing it only on Comics.
      </p>
      <p>
      If you are subscribed to a Creator then any ads that may be shown on there content will be removed.
      </p>
      <p>
      We have ads served through Google Adsense and so information on how they serve ads are on there site.
      </p>
      <p>
      Mythos Makers will not use information gethered on Mythos Makers to sell to third parties for any reason.
      </p>
      <p>
      For information on ads and how we use data, please refer to our
      <a href='/privacypolicy' style={{fontWeight: 'bolder', marginRight: '5px', marginLeft: '5px'}}>
      Privacy Policy
      </a>
      for more information.
      </p>
    </div>
  )
}

export default Ads