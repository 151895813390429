import React, { useEffect, useState } from 'react';
import '../stylesheets/footer.css'

function Footer() {
  return(
    <div className="footer">
      <a href="/about">About Mythos Makers</a>
      <a href="/tos">Terms of Service</a>
      <a href="/privacy-policy">Privacy Policy</a>
      <a href="/california">California Privacy Rights</a>
      {/* <a href="/about-ads">About Ads</a> */}
      <a href="/copyright">Copyright + Content Use</a>
      <a href="/contact">Contact Us</a>
    </div>
  )
}

export default Footer;