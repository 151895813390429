import React, { useEffect, useMemo, useState, useRef } from 'react';
import sierrabell from '../../images/thumbnails/sierrabell.png'
import valkyrie from '../../images/thumbnails/valkyrie.png'
import kaikaku from '../../images/thumbnails/kaikaku.png'
import dungeon from '../../images/thumbnails/dungeon.jpg'
import {Helmet} from "react-helmet";

function AllComics(){

  return(
    <div>
      <Helmet>
        <title>Mythos Makers - Comics</title>
        <meta name="description" content="All of Mythos Makers Comic Series" />
      </Helmet>
      <a href="/comics/sierra-bell"><img src={sierrabell} alt="" class="thumbnail" /></a>
      <a href="/comics/valkyrie"><img src={valkyrie} alt="" class="thumbnail" /></a>
      <a href="/comics/dungeon"><img src={dungeon} alt="" class="thumbnail" /></a>
      <a href="/comics/kaikaku"><img src={kaikaku} alt="" class="thumbnail" /></a>
    </div>
  )
}
export default AllComics;
