import React, { useEffect, useMemo, useState, useRef } from 'react';
import {
  BrowserRouter as Router,
  Link,
  Route,
  Routes,
  useParams,
} from "react-router-dom";
import Item from '../Item'
import {Helmet} from "react-helmet";
import '../../stylesheets/list.css'

import sierraBellHeader from '../../images/headers/sierra-bell.png'
import valkyrieHeader from '../../images/headers/valkyrie.png'
import kaikakuHeader from '../../images/headers/kaikaku.png'
import dungeonHeader from '../../images/headers/Dungeon.jpg'

function Series(){
  const [episodes, setEpisodes] = useState({});
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState('')
  const [headerImage, setHeaderImage] = useState(null)
  const [title, setTitle] = useState("")
  const [description, setDescription] = useState("")

  const [currentSeason, setCurrentSeason] = useState(1)
  const [seasons, setSeasons] = useState([])

  const { series, episode } = useParams()

  const getSeasons = async () => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_SERVER}/comic/checkSeasons/${series}`)
    .then((response) => response.json({}))
    setSeasons(response)
  }

  const getResultsData = async () => {
    if(isLoading == true){  
      getSeasons()
      const response = await fetch(`${process.env.REACT_APP_BACKEND_SERVER}/comic/bySeason/${series}/${currentSeason}`)
      .then((response) => response.json({}))
      setEpisodes(response)
      setLoading(false)
      if(series == "sierra-bell"){
        setHeaderImage(sierraBellHeader)
        setTitle("Sierra Bell: The Magic's Curse")
        setDescription("After Sierra Bell goes into hiding for one hundred years, she decides to go out and try and protect others from the evil that use magic for there evil deeds. Though it may prove more difficult than she may anticipate.")
      }
      if(series == "valkyrie"){
        setHeaderImage(valkyrieHeader)
        setTitle("Project: Valkyrie")
        setDescription("After the great Ironside War, Earth itself has become uninhabitable, forcing humanity to the skies to survive. After using her secret abilities to stop a gas station robbery, a young orphan named Ichara Berin is selected as a recruit for the VALKYRIE project, a secret team of superheroes to protect humanity's last hope for survival, Yggdrasil. Now Ichara and the Valkyries fight for Yggdrasil's survival, but its seedy underbelly might just catch up with them first.")
      }
      if(series == "kaikaku"){
        setHeaderImage(kaikakuHeader)
        setTitle("The Tale Of Kaikaku")
        setDescription("Space is vast and large! Many different people and different species! Each galaxy and planet has there mysteries and there secrets. What troubles are ahead in the awaiting adventure!")
      }
      if(series == "dungeon"){
        setHeaderImage(dungeonHeader)
        setTitle("Dungeon Henshin")
        setDescription("Join Maple, Clover, and all of their friends as they try to save the Fermata Isles with the power of music (and a little help from a magical dragon)!")
      }
    }
  };

  const resetResultsData = async (a) => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_SERVER}/comic/bySeason/${series}/${a}`)
    .then((response) => response.json({}))
    setEpisodes(response)
    setLoading(false)
    if(series === "sierra-bell"){
      setHeaderImage(sierraBellHeader)
      setTitle("Sierra Bell: The Magic's Curse")
      setDescription("After Sierra Bell goes into hiding for one hundred years, she decides to go out and try and protect others from the evil that use magic for there evil deeds. Though it may prove more difficult than she may anticipate.")
    }
  }

  const switchSeason = (e, a) => {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    resetResultsData(a)
  }

  const header = {
    width: "100%",
    paddingBottom: '20px'
  }

  const seasonsStyle = {
    display: "flex",
    gap: "30px",
    color: "white",
    flexWrap: "wrap",
    justifyContent: "center"
  }

  useEffect(() => {
    getResultsData();
  });

  if (isLoading === true) return 'Loading...'
  if (error) return 'Error has occurred'
  if (seasons.length === 0){
    return(
      <div class="contentList" >
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
        </Helmet>
        <img src={headerImage} alt="" style={header} />
        <h1>{title}</h1>
        <h2 style={{width: "60%"}}>{description}</h2>
        <div style={seasonsStyle}>
        </div>
        {/* <div>{JSON.stringify(episodes)}</div> */}
        <h2>No New Episodes Yet!</h2>
        <h1>Check Back October 8th!</h1>
        {/* <img src="https://storage.googleapis.com/mythosmakers_storage/SBPG1.png" alt="" /> */}
      </div>
    )
  }
  return (
    <div class="contentList" >
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
      <img src={headerImage} alt="" style={header} />
      <h1>{title}</h1>
      <h2 style={{width: "60%"}}>{description}</h2>
      <div style={seasonsStyle}>
        {seasons.map(num =>(
          <h2 >
            <a href="" class="seasonButton" onClick={(e) => switchSeason(e, num)}>
              Season {num}
            </a>
          </h2>
        ))}
      </div>
      {/* <div>{JSON.stringify(episodes)}</div> */}
      {episodes.map(thing =>(
        <Item title={thing["Title"]} icon={thing["Thumbnail"]} link={`/comics/${series}/${thing["ID"]}`} number={thing["Episode"]} season={thing["Season"]} />
      ))}
      {/* <img src="https://storage.googleapis.com/mythosmakers_storage/SBPG1.png" alt="" /> */}
    </div>
  )

}
export default Series;