import React, { useEffect, useMemo, useState, useRef } from 'react';
import johncrandom from '../../images/thumbnails/johncrandom.png'
import frog from '../../images/thumbnails/frog.png'
import showerbrewcrew from '../../images/thumbnails/showerbrewcrew.png'
import rain from '../../images/thumbnails/rain.png'
import jltt from '../../images/thumbnails/f3b.png'
import {Helmet} from "react-helmet";

function AllVideos(){

  return(
    <div>
      <Helmet>
        <title>Mythos Makers - Videos</title>
        <meta name="description" content="All of Mythos Makers Video Series" />
      </Helmet>
      <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/channel/UCQYXlno1h0XEfOtmWEsCuSA"><img src={johncrandom} alt="" class="thumbnail" /></a>
      <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/@MrRainGaming"><img src={rain} alt="" class="thumbnail" /></a>
      <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/@afrenchfrog4681"><img src={frog} alt="" class="thumbnail" /></a>
      <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/@F3B_Podcast"><img src={jltt} alt="" class="thumbnail" /></a>
    </div>
  )
}
export default AllVideos;