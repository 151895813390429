import React, { useEffect, useMemo, useState, useRef } from 'react';
import { Carousel } from 'react-responsive-carousel';
import {
  BrowserRouter as Router,
  Link,
  Route,
  Routes,
  useParams,
} from "react-router-dom";

import logo from '../../images/shenanigans/Background.png'
import '../../stylesheets/showgame.css'
import dmn7Art from '../../images/dmn7/Header.png'
import labrat from '../../images/dmn7/labrat.png'
import shenanigansScreenshotOne from '../../images/shenanigans/ScreenShot1.png'
import shenanigansScreenshotSeven from '../../images/shenanigans/ScreenShot7.png'
import shenanigansScreenshotThree from '../../images/shenanigans/ScreenShot3.png'
import {Helmet} from "react-helmet";

function ShowGame(){

  const { game } = useParams()
  if(game == "labrat"){
    return(
      <div>
        <Helmet>
          <title>LabRat</title>
          <meta name="description" content="Abandoned Lab, Reset Power, RUN! A point and click game of surviving an abandoned lab, discovering it's secrets while surviving the creature within." />
        </Helmet>
        <img src={labrat} class="shenanigansLogo" ></img>
        <h2>
          Abandoned Lab, Reset Power, RUN! A point and click game of surviving an abandoned lab, discovering it's secrets while surviving the creature within.
        </h2>
        <h2>
          <iframe frameborder="0" height="190" src="https://store.steampowered.com/widget/1454320/" width="646"></iframe>
        </h2>
      </div>
    )
  }
  if(game == "dmn7"){
    return(
      <div>
        <Helmet>
          <title>DMN7</title>
          <meta name="description" content="Don't let yourself get killed by the mechanical beast known as DMN7 in this underground, stealth, VR experience." />
        </Helmet>
        <img src={dmn7Art} class="shenanigansLogo" ></img>
        <h1>
          Don't let yourself get killed by the mechanical beast known as DMN7 in this underground, stealth, VR experience.
        </h1>
        <h2>
          <iframe frameborder="0" height="190" src="https://store.steampowered.com/widget/1519420/" width="646"></iframe>
        </h2>
      </div>
    )
  }
  if(game == "shenanigans"){
     return(
      <div>
        <Helmet>
          <title>Shenanigans</title>
          <meta name="description" content="An absolutely ridiculous Action Adventure of a Brother and Sister, a Witch Cat and her Beast going on a quest to restore there favorite sleeping spot after Mr. Wector takes the Sun Energy from The Great Sunflower! Simon, Sebrina, Rae, and Beast must stop him from taking over the world!" />
        </Helmet>
        <img src={logo} class="shenanigansBackground" ></img>
        <div class="content">
          <div>
            <iframe src="https://store.steampowered.com/widget/1936640/" frameborder="0" width="646" height="190"></iframe>
          </div>
          <br />
          <br />
          <iframe width="560" height="315" src="https://www.youtube.com/embed/5GqogbQH4_8?si=hv-HWp3O5RIavV5u" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          <h1>
            Let the Shenanigans Begin!
          </h1>
          <h2>
            The Evil Dr. Wector has taken the Great Sunflower’s Sun Energy for his evil plan and now it is up to you to get it all back! Join the four hooligans, Simon, Sebrina, Rae, and Beast, in a wacky and goofy 2D adventure!
          </h2>
          <img src={shenanigansScreenshotOne} style={{width: '50%', borderRadius: '60px'}} />
          <h1>
            A Wild 2D Adventure!
          </h1>
          <h2>
            From the mind of The Escaped Mental Patient, John Coday, explore a world of goofy characters and zany places created by Mythos Makers and Co. Run, Jump, Wall Run, and Swing Your Hammer as you go through beautiful levels and take down enemies along the way. You can run through all the levels or take your time. Take advantage of the smooth and fast paced 2D platforming giving you ultimate control of the adventure! Explore the world or speed through to save it! The choice is yours!
          </h2>
          <img src={shenanigansScreenshotSeven} style={{width: '50%', borderRadius: '60px'}} />
          <h1>
            A wacky cast of Characters
          </h1>
          <h2>
            Play as one of four nut cases. The bat wielding madman, Simon. His violent hamer swinging sister, Sebrina. A kind witch named Rae, and her empty headed magical creature, Beast. 
          </h2>
          <img src={shenanigansScreenshotThree} style={{width: '50%', borderRadius: '60px'}} />
          <h1>
            Download Now!
          </h1>
          <iframe src="https://store.steampowered.com/widget/1936640/" frameborder="0" width="646" height="190"></iframe>
        </div>
      </div>
    )   
  }
  
}
export default ShowGame;