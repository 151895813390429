import React, { useEffect, useMemo, useState, useRef } from 'react';
import '../stylesheets/item.css'
import TestThumb from '../images/testThumb.png'

function Item({icon, title, number, link, season}){

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  if (windowSize[0] > 900){
    return(
      <a href={link} class="itemOuterContainer">
        <div class="firstPart">
          <img src={`https://storage.googleapis.com/mythosmakers_storage/${icon}`} alt="" />
          <h1>
            {title}
          </h1>
        </div>
        <div class="itemEnd" >
          <h1>
            S: {season}
            -
            E: {number}
          </h1>
        </div>
      </a>
    )
  }else{
    return(
    <a href={link} class="itemOuterContainer">
      <img src={`https://storage.googleapis.com/mythosmakers_storage/${icon}`} alt="" />
        <h1>
          {title}
        </h1>
        <h1>
          S: {season}
          -
          E: {number}
        </h1>
    </a>
    )
  }

}

export default Item;