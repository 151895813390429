import '../../stylesheets/creatorDashboard.css'
import React, { useEffect, useMemo, useState, useRef } from 'react';
import {
  BrowserRouter as Router,
  Link,
  Route,
  Routes,
  useParams,
} from "react-router-dom";
import Item from '../Item'
import '../../stylesheets/list.css'

import sierraBellHeader from '../../images/headers/sierra-bell.png'

function DashboardComicView(){
  const [episodes, setEpisodes] = useState({});
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState('')
  const [headerImage, setHeaderImage] = useState(null)

  const { series, episode } = useParams()

  const [title, setTitle] = useState('')

  const getResultsData = async () => {
    if(isLoading == true){  
      const response = await fetch(`${process.env.REACT_APP_BACKEND_SERVER}/comic/all/${series}`)
      .then((response) => response.json({}))
      setEpisodes(response)
      setLoading(false)
      if(series == "sierra-bell"){
        setTitle("Sierra Bell: The Magic's Curse")
        setHeaderImage(sierraBellHeader)
      }
    }
  };
  
  useEffect(() => {
    getResultsData();
  });

  if (isLoading === true) return 'Loading...'
  if (error) return 'Error has occurred'
  return(
    <div>
      <h2>{title}</h2>
      
      <div class="contentList">
        <a href={`/dashboard/comics/${series}/addepisode`} class="login">Add Episode</a>
        {episodes.map(thing =>(
          <Item title={thing["Title"]} icon={thing["Thumbnail"]} link={`/dashboard/comics/${series}/editepisode/${thing["ID"]}`} number={thing["Episode"]} season={thing["Season"]} />
        ))}
      </div>
    </div>
  )
}
export default DashboardComicView;