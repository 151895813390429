import React, { useEffect, useState } from 'react';

const content = {
  margin: '60px'
}

function Privacy(){
return(
<div style={content}>
  <h1>
  Privacy Policy
  </h1>
  <p>
  Last Modified 1/20/22
  </p>
  <p>
  Privacy Policy can change in corilation with new features, comics, games, shows. podcasts, or events. We will update users when our privacy policy changes for any reason.
  </p>
  <h3>
  SCOPE OF POLICY
  </h3>
  <p>
  This policy describes the processing of information provided or collected on the sites and applications where this policy is posted. We follow this privacy policy in accordance with applicable law in the places where we operate. In some cases, we may provide additional data privacy notices specific to certain products, practices, or regions. Those terms are to be read in conjunction with this policy.
  </p>
  <p>
  Please keep in mind that when you provide information to us on a thrid party site or platfrom, the information you provide may be seperatly collected by the third party platform. The information we collect is covered by this privacy policy and the information the third party site or platform collects is subject to the third party site or platform's privacy practices. Privacy choices you have made on the third party site or platform will not apply to our use of the information we have collected through our applications. Our sites and applications may contain links to other sites not owned or controlled by us and we are not responsible for the privacy practices of those sites. We encourage you to read the privacy policies of other sites that may collect your personal information.
  </p>
  <h3>
  WHO WE ARE
  </h3>
  <p>
  We are a general entertainment company that includes multiple individuals and brands. When you visit, shop, or create an account with us, or use sites and applications we control, your information is controlled by a member of Mythos Makers.
  </p>
  <h3>
  TYPES OF INFORMATION WE COLLECT
  </h3>
  <p>
  -Registration information you provide when creating an account, enter a promotion, or link your profile on a third party website or platform with your registraion account including your first and last name, country of residence, gender, date of birth, email address, username, and password.
  </p>
  <p>
  -Transaction information you provide when you request information, contact us, or pruchase, return, request, or exchange a product or service from us, such as your postal address, telephone number, and payment information.
  </p>
  <p>
  -Information you provide in public forums on our sites and applications, such as your public posts.
  </p>
  <p>
  -Information we obtain from a third party site or application.
  </p>
  <p>
  -Activity infromation about your use, and the use by any person(s) you authorize through your account, of our sites and applications, such as the content you view or post, how often you use our services, and your preferences.
  </p>
  <p>
  -Usage, viewing, technical, and device data when you visit our sites, use our applicaions on third party sites or platforms, or open emails we send.
  </p>
  <h3>
  HOW WE COLLECT YOUR INFORMATION
  </h3>
  <p>
  -We collect information your provide to our databases through creating accounts, posting on forums, respond to surveys, purchase products, or link third party accounts.
  </p>
  <p>
  -We collect information through a variety of technologies, such as cookies, Flash cookies, pixels, tags, software development kits, and our applications, including when you visit our sites and applications or use our applications on third party sites and applications using one or more devices, whether you are logged in or not.
  </p>
  <p>
  -We collect information using analytics tools, including when you visit our sites and applications or use our applications on third party sites, platforms, and applications.
  </p>
  <h3>
  USE OF YOUR INFORMATION BY MYTHOS MAKERS
  </h3>
  <p>
  Mythos Makers is responsible for and may use your information for purposes described in this policy. Members of Mythos Makers have access to information only relevent to there particular area (such as creators only have information on there content) and, unless prohibited under applicable law, for use on their behalf for the purposes described in this policy. Consistent with applicable law and choices and controls that may be available to you, we may use information collected from you, or from devices associated with you, to:
  </p>
  <p>
  -Provide you with the experiences, products, and services you request, view, engage with, or purchase.
  </p>
  <p>
  -Communicate with you about your account or transactions with us and send you information or request feedback about features on our sites and applications or changes to our policies.
  </p>
  <p>
  -Send you offers and promotions for our products and services or third party products or services.
  </p>
  <p>
  -Operate, investigate and prevent activities that may violate our policies, pose safety issues, or be fraudulent or illegal.
  </p>
  <p>
  -Notify you of product recalls or safety issues.
  </p>
  <h3>
  SHARING YOUR INFORMATION WITH OTHER ENTITIES
  </h3>
  <p>
  We will not share your personal information with a third party outside Mythos Makers except in limited circumstances, including:
  </p>
  <p>
  -When companies perform services on our behalf, like package delivery, customer service, and transactions through in site implemented systems including Stripe and Paypal.
  </p>
  <p>
  -We are prohibited from selling infromation about our users to third party entities for any reason.
  </p>
  <h3>
  CHILDREN'S PRIVACY
  </h3>
  <p>
  We recognize that we need to place further protections of personal protections of children on our sites and applications. Whilst we do not provide content aimed towards children and prevent individuals under the age of 16 to register (create accounts) on our sites and applications, we ask parents to watch what there children access and use our privacy settings to make sure your children are safe.
  </p>
  <p>
  We strictly do not collect and use information regarting any individual considered a minor.
  </p>
  <h3>
  CHANGES TO THIS PRIVACY POLICY
  </h3>
  <p>
  From time to time, we may change this privacy policy to accommodate new technologies, industry practices, regulator requirements or for other purposes. We will provide notice to you when changes are made and provide was to give concent to use of your information.
  </p>
  <h3>
  CALIFORNIA RESIDENTS' RIGHTS
  </h3>
  <p>
  Notice to California Residents: If you are a California resident, you may have additional privacy rights. Visit our California Privacy Rights page for more information.
  </p>
  <h3>
  UK AND EU RESIDENTS' RIGHTS
  </h3>
  <p>
  Notice to UK and EU Residents: If you are a UK and EU resident, you may have additional privacy rights. Visit our UK and EU Privacy Rights page for more information.
  </p>
  <h3>
  BRAZILIAN RESIDENTS' RIGHTS
  </h3>
  <p>
  Notice to Brazilian Residents: If you are a Brazilian resident, you may have additional privacy rights. Visit our Brazilian Privacy Rights page for more information.
  </p>
  <h3>
  MYTHOS MAKERS
  </h3>
  <p>
  Mythos Makers refers to Mythos Makers and its subsidiary and affiliated entities, which offer products and services under various brand names. These entities engage in a number of buisnesses and practices. Mythos Makers brands and entities include, among others, the following:
  </p>
  <p>
  -Mythos Makers
  </p>
  <p>
  -Mythos Makers Comics
  </p>
  <p>
  -Mythos Makers Games
  </p>
  <p>
  -Mythos Makers Shows
  </p>
  <p>
  -Mythos Makers Podcasts
  </p>
  <p>
  -Mythos Makers Originals
  </p>
  <p>
  -John Coday
  </p>
  <p>
  -Garden Wolf
  </p>
  <p>
  -DizzyDummyBoy
  </p>
  <p>
  -ZM_Art
  </p>
  <p>
  -Shower Brew Crew
  </p>
  <p>
  -Canadian Moose
  </p>
  <p>
  -A French Frog
  </p>
  <p>
  -Mr Rain Gaming
  </p>
</div>
)}

export default Privacy;