import React, { useState } from 'react';
import { BrowserRouter, Route, Switch, useNavigate } from 'react-router-dom';
import axios from 'axios'
import '../stylesheets/login.css'
import Cookies from "universal-cookie";
const cookies = new Cookies();

function Login(){
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async () => {

    const customConfig = {
        headers: {
        'Content-Type': 'application/json'
        }
    };

    const user = JSON.stringify({
      name: username,
      password: password
    })

    const request = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json; charset=utf-8' },
      url: `${process.env.REACT_APP_BACKEND_SERVER}/loginapi/loginuser`,
      data: user
    }

    axios(request)
    .then((result) => {
      cookies.set("currentUserID", result.data.userID, { path: "/",})
      cookies.set("currentUsername", result.data.username, { path: "/",})
      if(result.data.userID != null){
        navigate("/")
      }
      
    })


    // const response = await fetch('${process.env.REACT_APP_BACKEND_SERVER}/loginapi/loginuser', request)
    // .then((response) => console.log(response))
    

  }

  return(
    <div class="login">
    <form onSubmit={e => e.preventDefault()}>
      <label>
        <h2>Username</h2>
        <input type="text" onChange={e => setUserName(e.target.value)}/>
      </label>
      <label>
        <h2>Password</h2>
        <input type="password" onChange={e => setPassword(e.target.value)}/>
      </label>
      <div>
        <br />
        <button onClick={handleSubmit}>Submit</button>
      </div>
    </form>
    </div>
  )
}

export default Login