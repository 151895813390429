import Plyr from "plyr-react"
import "plyr-react/plyr.css"
import "./player.css"
import {Helmet} from "react-helmet";

import React, { useEffect, useMemo, useState, useRef } from 'react';
import { Carousel } from 'react-responsive-carousel';
import {
  BrowserRouter as Router,
  Link,
  Route,
  Routes,
  useParams,
} from "react-router-dom";
import Slider from "react-slick";

import "@mkhuda/react-shaka-player/dist/ui.css";
import 'shaka-player-react/dist/controls.css';
import { ReactShakaPlayer } from "@mkhuda/react-shaka-player";

//shortyWithSomeDayOf6000

function VideoShow() {

  const [episode, setEpisode] = useState({});
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(false)

  const { series, episodeId } = useParams()

  const {sources, setSources} = useParams([])

  let [mainPlayer, setMainPlayer] = useState({});
  
  let plyrProps = {
    type: 'video',
    sources: sources,
  };

  const getResultsData = async () => {
    if(isLoading){  
      const response = await fetch(`${process.env.REACT_APP_BACKEND_SERVER}/videos/byEpisode/${series}/${episodeId}`)
      .then((response) => response.json({}))
      .then((response) => {
        setEpisode(response)
        setLoading(false)
      })
      .catch((err) => {
        setError(true)
      });
    }
  };

  const setUpPlayer = (e) => {
    let temp = []
    if(e['1080p'] !== ""){
      temp = [{
        src: e['1080p'],
        type: 'video/mp4',
        size: '1080'
      }, ...temp]
    }
    if(e['720p'] !== ""){
      temp = [{
        src: e['720p'],
        type: 'video/mp4',
        size: '720'
      }, ...temp]
    }
    if(e['480p'] !== ""){
      temp = [{
        src: e['480p'],
        type: 'video/mp4',
        size: '480'
      }, ...temp]
    }
    if(e['360p'] !== ""){
      temp = [{
        src: e['360p'],
        type: 'video/mp4',
        size: '360'
      }, ...temp]
    }
    if(e['240p'] !== ""){
      temp = [{
        src: e['240p'],
        type: 'video/mp4',
        size: '240'
      }, ...temp]
    }
    if(e['144p'] !== ""){
      temp = [{
        src: e['144p'],
        type: 'video/mp4',
        size: '144'
      }, ...temp]
    }
    if(e['4K'] !== ""){
      temp = [{
        src: e['4K'],
        type: 'video/mp4',
        size: '2160'
      }, ...temp]
    }
    if(e['2K'] !== ""){
      temp = [{
        src: e['2K'],
        type: 'video/mp4',
        size: '1440'
      }, ...temp]
    }
    let final = {
      type: 'video',
      sources: temp,
    }
    return(final)
  }
  
  const pylrOptions = {
    type: 'video',
    controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'quality', 'captions', 'settings', 'pip', 'airplay', 'fullscreen'],
    settings: ['captions', 'quality', 'speed', 'loop']
  }

  const playerCSS = {
    width: "65%",
    marginRight: '30px'
  }

  const playerContainer = {
    display: 'flex',
    margin: "50px"
  }

  const descriptionStyles ={
    width: "34%"
  }

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    const { player, videoElement } = mainPlayer;
    getResultsData();
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener('resize', handleWindowResize);

    if (player) {
      async function play() {
        await player.load(episode["1080p"]);
        videoElement.play();
      }
      play();
    }


    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [mainPlayer]);

  const mobileDescription = {
    margin: "25px"
  }

  const shakaPlayerConf = {
    'controlPanelElements': ['play_pause','mute', 'volume', 'rewind', 'fast_forward', 'time_and_duration', 'spacer', 'captions', 'airplay', 'cast', 'overflow_menu', 'picture_in_picture', 'fullscreen'],
    'overflowMenuButtons' : ['statistics', 'cast', 'airplay', 'loop', 'playback_rate', 'language', 'captions', 'quality'],
    'playbackRates': [0.1, 0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2, 2.5, 4],
    'enableKeyboardPlaybackControls': true,
    'keyboardSeekDistance': 10
  }

  if (isLoading === true) return 'Loading...'
  if (error) return 'Error has occurred'
  if (episode == null) return 'Loading...'
  if (windowSize[0] > 900){
  return (
    <>
      <Helmet>
        <title>Mythos Makers - {episode["Title"]} </title>
        <meta name="description" content={episode["description"]} />
      </Helmet>
      <div style={playerContainer}>
        <div style={playerCSS}>
          {/* <Plyr source={setUpPlayer(episode)} options={pylrOptions} style={playerCSS} /> */}
          <ReactShakaPlayer
            uiConfig={shakaPlayerConf}
            playerClassName="player-class-name"
            onLoad={(player) => setMainPlayer(player)}
          />
        </div>
        <div style={descriptionStyles}>
          <h2>{episode.Title}</h2>
          <p >{episode.Description}</p>
        </div>
      </div>
    </>
  );
  }else{
    return(
      <>
        <ReactShakaPlayer
          uiConfig={shakaPlayerConf}
          playerClassName="player-class-name"
          onLoad={(player) => setMainPlayer(player)}
        />
        <div style={mobileDescription}>
          <h2>{episode.Title}</h2>
          <p >{episode.Description}</p>
        </div>
      </>
    )
  }
}

export default VideoShow;