import React, { useEffect, useState } from 'react';
import sierraVertical from '../../images/verticals/Sierra Bell.png'
import valkyrieVertical from '../../images/verticals/Project Valkyrie.png'
import shenanigansVertical from '../../images/verticals/Shenanigans.png'
import johnVertical from '../../images/verticals/John Coday.png'
import gardenVertical from '../../images/verticals/GardenWolf.png'
import dizzyVertical from '../../images/verticals/DizzyDummyBoy.png'
import zmVertical from '../../images/verticals/ZM_Art.png'
import sierrabell from '../../images/thumbnails/sierrabell.png'
import valkyrie from '../../images/thumbnails/valkyrie.png'
import kaikaku from '../../images/thumbnails/kaikaku.png'
import shenanigans from '../../images/thumbnails/shenanigans.png'
import dmn7 from '../../images/thumbnails/dmn7.png'
import labrat from '../../images/thumbnails/labrat.png'
import johncrandom from '../../images/thumbnails/johncrandom.png'
import showerbrewcrew from '../../images/thumbnails/showerbrewcrew.png'
import emblem from '../../images/sbc_badge.png'
import {Helmet} from "react-helmet";

const about_content = {
  margin: '80px'
}

const emblem_style = {
  width: '20%'
}

function ShowerBrew(){
  return(
    <div style={about_content}>
      <Helmet>
        <title>Shower Brew Crew</title>
        <meta name="description" content="Shower Brew Crew" />
      </Helmet>
      <h1>The Shower Brew Crew</h1>
      <h2>
        <img src={emblem} alt="logo" style={emblem_style} />
      </h2>
      <h2>
        The Shower Brew Crew consist of Canadian Moose, redheart, Evil Redneck, and ShadowDragon. They are a group of content creators who mostly stream on Twitch and cause all sorts of chaos!
      </h2>
      <h1>
        Crew's Podcast
      </h1>
      <div className="blocks">
        <a href="/videos/showerbrewcrew"><img src={showerbrewcrew} alt="" class="thumbnail" /></a>
      </div>
    </div>
  )
}

export default ShowerBrew;