import './App.css';
import "plyr-react/plyr.css";
import React, { Component, useEffect, useState } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { BrowserRouter as Router, Route, Routes, Navigate} from 'react-router-dom'
import Home from './components/Home';
import Login from './components/Login'
import AllComics from './components/comics/allComics'
import ComicEpisodeCom from './components/comics/ComicEpisode'
import Series from './components/comics/Series';
import AllGames from './components/games/allGames'
import ShowGame from './components/games/showGame'
import AllVideos from './components/videos/allVideos';
import SignUp from './components/SignUp';
import VideoSeries from './components/videos/VideoSeries';
import ShowVideos from './components/videos/show'
import CreatorDashboard from './components/creator/creatorDashboard';
import DashboardComicView from './components/creator/dashboardComicView';
import AddComicEpisode from './components/creator/addComicEpisode';
import EditComicEpisode from './components/creator/editComicEpisode';
import DashboardVideoView from './components/creator/dashboardVideoView';
import AddVideoEpisode from './components/creator/addVideoEpisode';
import EditVideoEpisode from './components/creator/editVideoEpisode';
import About from './components/About'
import Privacy from './components/PrivacyPolicy'
import TOS from './components/TOS'

import AWS from 'aws-sdk';
import California from './components/California';
import Ads from './components/Ads';
import Contact from './components/Contact';
import CreatorIndex from './components/creators/CreatorIndex';
import JohnCoday from './components/creators/JohnCoday';
import GardenWolf from './components/creators/GardenWolf';
import ZM from './components/creators/ZM';
import Marcy from './components/creators/Marcy';
import ShowerBrew from './components/creators/ShowerBrew';
import Dizzy from './components/creators/Dizzy';
import Copyright from './components/Copyright';

AWS.config.update({
  accessKeyId: 'AKIAU4ICU6GXLIEFH6WX',
  secretAccessKey: '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'
})

//shortyWithSomeDayOf6000

function App() {

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Home></Home>}></Route>
          <Route path="/login" element={<Login></Login>}></Route>
          <Route path="/signup" element={<SignUp></SignUp>}></Route>

          <Route path="/about" element={<About></About>}></Route>
          <Route path="/privacy-policy" element={<Privacy></Privacy>}></Route>
          <Route path="/tos" element={<TOS></TOS>}></Route>
          <Route path="/california" element={<California></California>}></Route>
          <Route path="/about-ads" element={<Ads></Ads>}></Route>
          <Route path="/contact" element={<Contact></Contact>}></Route>
          <Route path='/copyright' element={<Copyright></Copyright>}></Route>

          <Route path="/creators" element={<CreatorIndex></CreatorIndex>}></Route>
          <Route path="/creators/johncoday" element={<JohnCoday></JohnCoday>}></Route>
          <Route path="/creators/gardenwolf" element={<GardenWolf></GardenWolf>}></Route>
          <Route path="/creators/zm" element={<ZM></ZM>}></Route>
          <Route path="/creators/marcy" element={<Marcy></Marcy>}></Route>
          <Route path='/creators/showerbrewcrew' element={<ShowerBrew></ShowerBrew>}></Route>
          <Route path="/creators/dizzydummy" element={<Dizzy></Dizzy>}></Route>

          <Route path="/comics" element={<AllComics></AllComics>}></Route>
          <Route path="/comics/:series" element={<Series></Series>} ></Route>
          <Route path="/comics/:series/:episodeId" element={<ComicEpisodeCom></ComicEpisodeCom>} ></Route>

          <Route path="/games" element={<AllGames></AllGames>} ></Route>
          <Route path="/games/:game" element={<ShowGame></ShowGame>} ></Route>

          <Route path="/videos" element={<AllVideos></AllVideos>}></Route>
          <Route path="/videos/:series" element={<VideoSeries></VideoSeries>}></Route>
          <Route path="/videos/:series/:episodeId" element={<ShowVideos></ShowVideos>}></Route>

          <Route path='/dashboard' element={<CreatorDashboard></CreatorDashboard>}></Route>
          <Route path="/dashboard/comics/:series" element={<DashboardComicView></DashboardComicView>} ></Route>
          <Route path="/dashboard/comics/:series/addepisode" element={<AddComicEpisode></AddComicEpisode>}></Route>
          <Route path="/dashboard/comics/:series/editepisode/:episodeId" element={<EditComicEpisode></EditComicEpisode>}></Route>
          <Route path="/dashboard/videos/:series" element={<DashboardVideoView></DashboardVideoView>} ></Route>
          <Route path="/dashboard/videos/:series/addepisode" element={<AddVideoEpisode></AddVideoEpisode>}></Route>
          <Route path="/dashboard/videos/:series/editepisode/:episodeId" element={<EditVideoEpisode></EditVideoEpisode>}></Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
