import React, { useEffect, useState } from 'react';
import sierraVertical from '../../images/verticals/Sierra Bell.png'
import valkyrieVertical from '../../images/verticals/Project Valkyrie.png'
import shenanigansVertical from '../../images/verticals/Shenanigans.png'
import johnVertical from '../../images/verticals/John Coday.png'
import gardenVertical from '../../images/verticals/GardenWolf.png'
import dizzyVertical from '../../images/verticals/DizzyDummyBoy.png'
import zmVertical from '../../images/verticals/ZM_Art.png'
import sierrabell from '../../images/thumbnails/sierrabell.png'
import valkyrie from '../../images/thumbnails/valkyrie.png'
import kaikaku from '../../images/thumbnails/kaikaku.png'
import shenanigans from '../../images/thumbnails/shenanigans.png'
import dmn7 from '../../images/thumbnails/dmn7.png'
import labrat from '../../images/thumbnails/labrat.png'
import johncrandom from '../../images/thumbnails/johncrandom.png'
import emblem from '../../images/Garden.png'
import {Helmet} from "react-helmet";

const about_content = {
  margin: '80px'
}

const emblem_style = {
  width: '20%'
}

function GardenWolf(){
  return(
    <div style={about_content}>
      <Helmet>
        <title>GardenWolf</title>
        <meta name="description" content="GardenWolf" />
      </Helmet>
      <h1>GardenWolf</h1>
      <h2>
        <img src={emblem} alt="logo" style={emblem_style} />
      </h2>
      <h2>
        GardenWolf is a visual artist who has worked on several projects apart of Mythos Makers. She has been a long time friend coming from the same community as John and has been an important part of Mythos Makers!
      </h2>
      <h1>
        Projects Garden Worked On!
      </h1>
      <div className='blocks'>
        <a href="/games/shenanigans"><img src={shenanigans} alt="" class="thumbnail" /></a>
        <a href="/games/dmn7"><img src={dmn7} alt="" class="thumbnail" /></a>
      </div>
    </div>
  )
}

export default GardenWolf;