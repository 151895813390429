import React, { useEffect, useState } from 'react';

const about_content = {
  margin: '80px'
}

function TOS() {
  return(
    <div style={about_content}>
      <h1>
        Terms of Service
      </h1>
      <p>
      Welcome, and thank you for your interest in Mythos Makers LLC. (“Mythos Makers,” “we,” “us,” or "Services") and our website at www.mythosmakers.com, along with our related websites, networks, desktop applications (each, a “Desktop Client”), VR headset applications (each, a “VR Client” and together with any Desktop Clients, “Clients”), and other services provided by us (collectively, including any Clients and SDKs, the “Platform”). This Mythos Makers Terms of Service (this “TOS”), is a legally binding contract between you and Mythos Makers regarding your use of the Platform.
      </p>
      <p>
      PLEASE READ THE FOLLOWING TERMS CAREFULLY. BY CLICKING “I ACCEPT,” OR BY USING THE PLATFORM OR ANY PART OF THE PLATFORM, YOU ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTOOD, AND AGREE TO BE BOUND BY THIS TOS. If you are not eligible, or do not agree to this TOS, then you do not have our permission to use the Platform or any part of the Platform.
      </p>
      <p>
        We may amend this Agreement. Any such amendment will be affective thirty(30) days following either our dispatch of a notice to you or our posting of the amendment on the Services. If you do not agree to any change to this Agreement, you must discontinue using the Services.
      </p>
      <p>
        You acknowledge and agree that, as provided in greater detail in this TOS:

        any Client is licensed, not sold to you;

        you may use the Platform only as set forth in this TOS;

        the Platform is provided “as is” without warranties of any kind and Mythos Makers’ liability to you is limited;

        disputes arising under this TOS will be resolved by binding arbitration, and by accepting this TOS, as described in greater detail in Section 26, you and Mythos Makers are each waiving the right to a trial by jury or to participate in a class action.
      </p>
      <h2>
        1. Platform Overview
      </h2>
      <p>
        Mythos Makers is a platform for reading Comics, playing Video Games, watching Video content, listening to Podcast and Music, and viewing Events. Subject to compliance with this TOS, and dependent on the then-current functionality of the Platform, users of the Platform (“Users”) may be able to use it to view, watch, and play comics, videos, audio and video podcast, and games.
      </p>
      <h2>
        2. Eligibility
      </h2>
      <p>
      You must be at least 16 years of age to use the Platform. By agreeing to this TOS, you represent and warrant to us that: (a) you are at least 16 years of age; (b) you have not previously been suspended or removed from the Platform; and (c) your registration and your use of the Platform complies with all applicable laws and regulations. If you are at least 16 but are under the age of 18, you may only use the Platform with your parent’s or guardian’s consent and your parent or guardian must provide consent after having read this TOS. In such a case you represent and warrant and covenant that both you and your parent or guardian have read and consent to this TOS (including all the conditions, limitations, disclaimers, and waivers provided herein). You further acknowledge and agree that by using the Platform you are accepting a benefit that you cannot return or disgorge and that we are relying on your representations regarding your ability to accept and perform this TOS, and you therefore waive any right you may have under applicable law to void, rescind, or otherwise avoid this TOS. If you are a parent or guardian consenting to this TOS on behalf of a child between the ages of 16 and 18, you additionally agree to guarantee and be fully responsible for that person’s performance hereunder, including all financial obligations or liability he or she may incur, and all disclaimers and waivers of applicable legal protections provided herein, and you agree to take all actions (including executing such additional documents or agreements) necessary to effect and perfect the foregoing. In all other cases, you represent that you are 18 years or older and fully competent to enter into this TOS, and you hereby agree and acknowledge that you have read, understood, and agree to be bound by this TOS without modification.
      </p>
      <p>
      If you are using the Platform on behalf of an entity, organization, or company, you represent and warrant that you have the authority to bind that organization to this TOS and you agree to be bound by this TOS on behalf of that organization.
      </p>
      <p>
      You further represent and warrant that you are not listed on or covered by any sanctioned person lists administered by the United States government or United Nations, including but not limited to the United States Department of Treasury’s List of Specially Designated Nationals and Blocked Persons, the United States Department of Commerce’s List of Denied Persons or Entity List, and the United Nations Security Council Sanctions. You further represent that you are not located in a country subject to comprehensive United States sanctions, including Iran, Syria, Cuba, North Korea, the Crimea region of Ukraine, the Donetsk People’s Republic or the Luhansk People’s Republic. Should you become listed on or otherwise subject to any such list or are in a country subject to comprehensive United States sanctions, then you agree to immediately cease use of the Platform.
      </p>
      <h2>
        3. Accounts and Registration
      </h2>
      <p>
        To access some features of the Platform, you must register for an account (your “User Account”). When you register, you may be required to provide us with some information about yourself, such as your email address or other contact information. You agree that the information you provide to us is accurate and that you will keep it accurate and up to date at all times. When you register, you will be asked to provide a password.
      </p>
      <p>
        You may also access certain features of the Platform using your login credentials for third-party accounts, such as your account with Steam, Discord, Patreon, WebToons, YouTube, Kick, or Twitch (each a “Third-Party Account”). The Platform may offer functionality allowing you to merge your User Account with a Third-Party Account.
      </p>
      <p>
        You are solely responsible for maintaining the confidentiality of the password(s) for your User Account and any Third-Party Accounts (each an “Account”), and you accept responsibility for all activities that occur under your Accounts. If you have reason to believe that your Account is no longer secure, then you must immediately notify us at support@mythosmakers.com.
      </p>
      <h2>
      4. Client Licenses
      </h2>
      <p>
      Subject to your complete and ongoing compliance with the terms and conditions in this TOS, Mythos Makers grants you a limited, non-commercial (except as otherwise specified in this TOS or other written agreement between you and Mythos Makers), non-exclusive, non-transferable, non-sublicensable, revocable license to each copy of any Client downloaded directly from Mythos Makers’ website or from a legitimate marketplace (such as Steam), solely in an executable application format, for your personal use for lawful purposes, on a single compatible device you own or control, and to access and use the Platform in accordance with this TOS. The terms “Desktop Client” and “VR Client,” as used in this TOS, include any updates or modifications to the applicable Client made available to you by or on behalf of Mythos Makers (unless provided with separate terms, in which case those terms are hereby incorporated into this TOS unless otherwise stated in those separate terms, and will control in the event of a conflict with the terms of this TOS, but only with respect to the applicable Client).
      </p>
      <h2>
        5. Content Licenses
      </h2>
      <p>
      a. Compatible Devices and Software. Use of the Services requires compatible devices, and certain software may require periodic updates, and your use of the Services may be affected by the performance of these elements. Mythos Makers Content and Mythos Makers Plus Content can be accessed with almost any Internet-connected computers, third party Platforms (such as Steam, YouTube, Kick, WebToons, or Twitch), or through applications available for certain mobile or other devices (each, a "Compatible Device"). Additionally, certain components of the Services or Service Tiers may only be compatible with a subset of Compatible Devices or require download of certain software, even if other aspects of the Services can be accessed on any Compatible Device.
      </p>
      <p>
        b. Internet Connection. You must have a high speed Internet Connection in order to access and use certain aspects of the Services.
      </p>
        <p>
          c. Streaming Content. The Content can be streamed through the Services over an active Internet connection.
        </p>
        <p>
        e. Content Availability. The content that we make available may be limited by law or by the rights that certain of our third-party content providers grant to us. Access to the Services from locations where we do not have rights, that are not offered on your Subscription, or where we do not make the Services available is prohibited. Certain Content available through the Mythos Makers Service subscription may not be available in all countries or territories within the Territory. The geographic restrictions and blackouts referenced in this paragraph will be enforced according to the location from which you are accessing the applicable Service or third-party services, and we may use different technologies and methods to verify your location. You may be required to enable location access on your device in order to access certain Content.
        </p>
        <p>
        f. Future Unavailability. It is possible that the Mythos Makers Service and/or some or all Content may not be available for streaming or downloading at any given time including (i) during any maintenance or update periods; (ii) any power or server outages; (iii) as a result of war, riots, strikes, social unrest; or (iv) as a result of other matters beyond the control of us or third parties. We will take reasonable efforts to provide you with as much prior notice as possible; however, we shall have no liability to you in such event. There may be times when we have to remove certain features or functionality and/or devices or platforms from being able to access the Service(s). We will do our best to let you know of any of these changes, usage rules and restrictions, but you acknowledge that we may do so in our sole discretion at any time without notice. You also agree that we will not be liable to you for any modification, suspension or discontinuance of the Mythos Makers Service, although if you are a subscriber and we suspend or discontinue your subscription to the Mythos Makers Service, we may, in our sole discretion, provide you with a credit, refund, discount or other form of consideration. However, if we terminate your account or suspend or discontinue your access to the Mythos Makers Service due to your violation of this Agreement, then you will not be eligible for any such credit, refund, discount or other consideration.
        </p>
        <p>
        g. Promotional and Experimental Features. In our continued assessment of the Services, we may from time to time, with respect to any or all of our users, experiment or otherwise offer certain features or other elements of the Mythos Makers Service, including promotions, features, advertisements, user interfaces, plans and pricing. You acknowledge that these are implemented in our sole discretion, may be subject to additional terms, and may not apply to every subscriber.
        </p>
        <h2>
          6. Use of Content
        </h2>
        <p>
          You may use the Content provided by Mythos Makers for personal viewing, watching, playing, or listening.
        </p>
        <p>
          You may not redistribute, copy, resell, or use to train AI any Content provided by the Service unless given permission by Mythos Makers or it's Members.
        </p>
        <p>
          You may not use any Content by the Service for monitary gain unless the content fits within the Four Pillars of Copyright Law, (1) The Purpose and Character of the Use, (2) The Nature of the Copyrighted Work, (3) The Amount or Substantiality of the Portion Used, and (4) The Effect of the Use on the Potential Market for or Value of the Work; or you have explicite written permission to do so.
        </p>
        <p>
          You may not use Content by the Service for the use of training AI models, including images, videos, voices, music, or writing, for any reason. 
        </p>
        <h2>
          7. Suspension, Termination, Discontinuation, or Modification of the Service
        </h2>
        <p>
        This TOS is effective beginning when you accept this TOS or first access or use the Platform, and ending when terminated as described below.
        </p>
        <p>
        You may terminate your User Account at any time by contacting customer service at support@mythosmakers.com or through account deletion functionality that may be provided to you via the Platform. If you log into the Platform using a Third-Party Account, you may only terminate your Account through emailing support@mythosmakers.com. If you terminate your Account, you remain obligated to pay all outstanding fees, if any, incurred prior to termination relating to your use of the Platform.
        </p>
        <p>
        If you violate any provision of this TOS, your permission from us to use the Platform will terminate automatically. In addition, Mythos Makers may in its sole discretion terminate your User Account on the Platform or suspend or terminate your access to the Platform at any time for any reason or no reason, with or without notice. Pre-paid fees, if any, will not be refunded if your User Account or access to the Platform is suspended or terminated due to violation of this TOS.
        </p>
        <p>
        We also reserve the right to terminate this TOS, or modify or discontinue all or any portion of the Platform at any time (including by limiting or discontinuing certain features of the Platform), temporarily or permanently, without notice to you. We will have no liability whatsoever on account of any change to the Platform, including any paid-for functionalities of the Platform, or any suspension or termination of your access to or use of the Platform. You should retain copies of any User Content you Post so that you have permanent copies if the Platform is modified in such a way that you lose access to User Content you Posted.
        </p>
        <p>
        Upon termination of this TOS, (a) your license rights will terminate and you must immediately cease all use of the Platform; (b) you will no longer be authorized to access your Account or the Platform; (c) you must pay Mythos Makers any unpaid amount that was due prior to termination, and (d) all payment obligations accrued prior to termination and any terms that survive within the Ancillary Agreements (defined below), will survive. You are solely responsible for retaining copies of any User Content you Post since upon termination of your Account, you may lose access rights to any User Content you Posted. If your Account has been terminated for a breach of this TOS, you are prohibited from creating a new account on the Platform using a different name, email address or other forms of account verification.
        </p>
        <h2>
          8. Third-Party Platforms and Linked Websites
        </h2>
        <p>
        Mythos Makers may provide tools through the Platform that enable you to export information, including User Content, to third-party services, including through features that allow you to sign into a third-party service using your Account credentials, link your account on Mythos Makers with an account on the third-party service, such as Steam, Patreon, YouTube, Kick, Twitch, WebToons, Twitter, or Facebook, or through our implementation of third-party buttons (such as “like” or “share” buttons). By using one of these tools, you hereby authorize Mythos Makers to transfer that information to the applicable third-party service. Third-party services are not under our control, and, to the fullest extent permitted by law, we are not responsible for any third-party service’s use of your exported information. The Platform may also contain links to third-party websites. Linked websites are not under our control, and we are not responsible for their content. Please be sure to review the terms of service and privacy policy of any third-party services before you share any User Content or information with such third-party services. Once sharing occurs, Mythos Makers will have no control over the information that has been shared.
        </p>
        <p>
        You agree that Mythos Makers may collect, use, and share information, including UDID and other information about you, your device, system, any Client, and peripherals, as disclosed in the Mythos Makers Privacy Policy, available at www.mythosmakers.com/privacy (or any successor URL designated by Mythos Makers) (the “Privacy Policy”). Please read the Privacy Policy carefully for information relating to our collection, use, storage, and disclosure of your personal information. The Privacy Policy is incorporated by this reference into, and made a part of, this TOS.
        </p>
        <h2>
          9. IP Ownership, Reservation of Rights
        </h2>
        <p>
        The Platform, including any Content, Clients, and Applications, is, as between you and Mythos Makers, owned and operated by Mythos Makers. The Clients, Content, visual interfaces, interactive features, information, graphics, design, compilation, computer code (including source code or object code), products, services, and all other elements of the Platform (the “Materials”), are protected by copyright, trade dress, patent, and trademark laws of the United States and other jurisdictions, international conventions, and all other relevant intellectual property and proprietary rights, and applicable laws. As between you and Mythos Makers, all Materials, including all associated intellectual property rights, are the sole and exclusive property of Mythos Makers, its subsidiaries or affiliated companies, or its third-party licensors. You may not sell, license, distribute, copy, modify, publicly perform or display, transmit, publish, edit, adapt, create derivative works from, or use the Materials except as expressly authorized under this TOS. Mythos Makers reserves all rights not expressly granted in this TOS. You do not acquire any right, title, or interest to the Materials, whether by implication, estoppel, or otherwise, except for the limited rights set forth in this TOS.
        </p>
        <h2>
        10. Third Party Software
        </h2>
        <p>
        The software you download comprises a package of components, including certain third-party software (“Third-Party Software'') provided under separate license terms (the “Third-Party Terms”). Your use of the Third-Party Software in conjunction with any Client or Content in a manner consistent with this TOS is permitted, however, you may have broader rights under the applicable Third-Party Terms and nothing in this TOS prevents, restricts, or is intended to prevent or restrict your use of the Third-Party Software under the applicable third-party licenses or to limit your use of Third-Party Software under those third-party licenses. The Platform may contain or be provided together with open source software. Each item of open source software is subject to its own applicable license terms. Copyrights to the open source software are held by the respective copyright holders indicated therein.
        </p>
        <h2>
          11. Feedback
        </h2>
        <p>
        If you provide Mythos Makers with any comments, bug reports, feedback, or modifications proposed or suggested by you for the Platform (“Feedback”), such Feedback is provided on a non-confidential basis (notwithstanding any notice to the contrary you may include in any accompanying communication), and Mythos Makers will have the right to use such Feedback at its discretion, including the incorporation of such suggested changes into the Platform. You hereby grant Mythos Makers a perpetual, unrestricted, irrevocable, nonexclusive, fully-paid up, royalty-free license under all rights necessary to exploit and use your Feedback for any purpose, including to improve the Platform and create other products and services. We will have no obligation to provide you with attribution for any Feedback you provide to us.
        </p>
        <h2>
          12. Indemnity
        </h2>
        <p>
        To the fullest extent permitted by law, you are responsible for your use of the Platform, and you will defend and indemnify Mythos Makers and its officers, directors, managers, members, employees, consultants, affiliates, subsidiaries, and agents and any licensors or suppliers that provide Materials (together, the “Mythos Makers Entities”) from and against every claim brought by a third party, and any related liability, damage, loss, and expense, including reasonable attorneys’ fees and costs, arising out of or in any way connected with: (a) your access to, use of, or alleged use of, the Platform; (b) your violation of any portion of this TOS, any representation, warranty, or agreement referenced in this TOS, or any applicable law or regulation; (c) your violation of any third-party right, including any intellectual property right or publicity, confidentiality, other property, or privacy right; or (d) any dispute or issue between you and any third party. We reserve the right, at our own expense, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you (without limiting your indemnification obligations with respect to that matter), and in that case, you agree to cooperate with our defense of that claim.
        </p>
        <h2>
          13. Disclaimers, No Warranties
        </h2>
        <p>
        ALL PARTS OF THE PLATFORM AND ALL MATERIALS AND CONTENT AVAILABLE THROUGH THE PLATFORM (INCLUDING ANY USER CONTENT) ARE PROVIDED “AS IS” AND ON AN “AS AVAILABLE” BASIS, WITHOUT WARRANTY OR CONDITION OF ANY KIND, EITHER EXPRESS OR IMPLIED. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE MYTHOS MAKERS ENTITIES DISCLAIM ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, RELATING TO THE PLATFORM AND ALL MATERIALS AND CONTENT AVAILABLE THROUGH THE PLATFORM (INCLUDING ANY USER CONTENT), INCLUDING: (a) ANY IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, QUIET ENJOYMENT, OR NON-INFRINGEMENT AND (b) ANY WARRANTY ARISING OUT OF COURSE OF DEALING, USAGE, OR TRADE. THE MYTHOS MAKERS ENTITIES DO NOT WARRANT THAT THE PLATFORM OR ANY PORTION OF THE PLATFORM, OR ANY MATERIALS OR CONTENT OFFERED THROUGH THE PLATFORM (INCLUDING ANY USER CONTENT), WILL BE UNINTERRUPTED, SECURE, OR FREE OF ERRORS, VIRUSES, OR OTHER HARMFUL COMPONENTS, AND DO NOT WARRANT THAT ANY OF THOSE ISSUES WILL BE CORRECTED. MYTHOS MAKERS EXPRESSLY DISCLAIMS ANY WARRANTIES OF ANY KIND WITH RESPECT TO THE ACCURACY OR FUNCTIONALITY OF LOCATION-BASED SERVICES, AND WITH RESPECT TO THE ACCURACY, VALIDITY, OR COMPLETENESS OF ANY INFORMATION OR FEATURES AVAILABLE THROUGH THE PLATFORM, OR THE QUALITY OR CONSISTENCY OF THE PLATFORM. MYTHOS MAKERS FURTHER DISCLAIMS ANY WARRANTY OR LIABILITY RELATED TO YOUR CARRIER’S NETWORK OR SERVICE.
        <br></br><br></br>
        NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM THE PLATFORM OR ANY MATERIALS OR CONTENT AVAILABLE THROUGH THE PLATFORM WILL CREATE ANY WARRANTY REGARDING ANY OF THE MYTHOS MAKERS ENTITIES OR THE PLATFORM THAT IS NOT EXPRESSLY STATED IN THIS TOS. YOU ASSUME ALL RISK FOR ANY DAMAGE THAT MAY RESULT FROM YOUR USE OF OR ACCESS TO THE PLATFORM, YOUR DEALING WITH ANY OTHER USER, AND ANY MATERIALS OR CONTENT AVAILABLE THROUGH THE PLATFORM. YOU UNDERSTAND AND AGREE THAT YOU USE ANY PORTION OF THE PLATFORM, AND USE, ACCESS, DOWNLOAD, OR OTHERWISE OBTAIN MATERIALS OR CONTENT THROUGH THE PLATFORM AND ANY ASSOCIATED SITES OR PLATFORMS, AT YOUR OWN DISCRETION AND RISK, AND THAT YOU ARE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR PROPERTY (INCLUDING YOUR COMPUTER SYSTEM OR MOBILE DEVICE USED IN CONNECTION WITH THE PLATFORM), OR THE LOSS OF DATA THAT RESULTS FROM THE USE OF THE PLATFORM OR THE DOWNLOAD OR USE OF THAT MATERIAL OR CONTENT.
        <br></br><br></br>
        THE LIMITATIONS, EXCLUSIONS AND DISCLAIMERS IN THIS SECTION 21 APPLY TO THE FULLEST EXTENT PERMITTED BY LAW. MYTHOS MAKERS DOES NOT DISCLAIM ANY WARRANTY OR OTHER RIGHT THAT MYTHOS MAKERS IS PROHIBITED FROM DISCLAIMING UNDER APPLICABLE LAW. SOME JURISDICTIONS MAY PROHIBIT A DISCLAIMER OF WARRANTIES AND YOU MAY HAVE OTHER RIGHTS THAT VARY FROM JURISDICTION TO JURISDICTION.
        </p>
        <h2>
          14. Limitation of Liability
        </h2>
        <p>
        TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT WILL THE MYTHOS MAKERS ENTITIES BE LIABLE TO YOU FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES (INCLUDING DAMAGES FOR LOSS OF PROFITS, GOODWILL, OR ANY OTHER INTANGIBLE LOSS) ARISING OUT OF OR RELATING TO YOUR ACCESS TO OR USE OF, OR YOUR INABILITY TO ACCESS OR USE, THE PLATFORM OR ANY MATERIALS OR CONTENT ON THE PLATFORM, OR OTHERWISE ARISING OUT OF THIS TOS (INCLUDING ANY ANCILLARY AGREEMENT), WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), STATUTE, OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT ANY MYTHOS MAKERS ENTITY HAS BEEN INFORMED OF THE POSSIBILITY OF DAMAGE.
        <br></br><br></br>
        EXCEPT AS PROVIDED IN SECTION 26.5.c, THE AGGREGATE LIABILITY OF THE MYTHOS MAKERS ENTITIES TO YOU FOR ALL CLAIMS ARISING OUT OF OR RELATING TO THE USE OF OR ANY INABILITY TO USE ANY PORTION OF THE PLATFORM OR OTHERWISE UNDER THIS TOS, WHETHER IN CONTRACT, TORT, OR OTHERWISE, IS LIMITED TO THE GREATER OF: (a) THE AMOUNT YOU HAVE PAID TO MYTHOS MAKERS FOR ACCESS TO AND USE OF THE PLATFORM IN THE 12 MONTHS PRIOR TO THE EVENT(S) OR CIRCUMSTANCES GIVING RISE TO SUCH CLAIM OR (b) $100.
        <br></br><br></br>
        SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES. ACCORDINGLY, THE ABOVE LIMITATION MAY NOT APPLY TO YOU.

        EACH PROVISION OF THIS TOS THAT PROVIDES FOR A LIMITATION OF LIABILITY, DISCLAIMER OF WARRANTIES, OR EXCLUSION OF DAMAGES IS INTENDED TO AND DOES ALLOCATE THE RISKS BETWEEN THE PARTIES UNDER THIS TOS. THIS ALLOCATION IS AN ESSENTIAL ELEMENT OF THE BASIS OF THE BARGAIN BETWEEN THE PARTIES. EACH OF THESE PROVISIONS IS SEVERABLE AND INDEPENDENT OF ALL OTHER PROVISIONS OF THIS TOS. THE LIMITATIONS IN THIS SECTION 22 WILL APPLY EVEN IF ANY LIMITED REMEDY FAILS OF ITS ESSENTIAL PURPOSE.
        </p>
        <h2>
          15. Third Party Disputes
        </h2>
        <p>
          MYTHOS MAKERS IS NOT AFFILIATED WITH ANY CARRIER, SERVICE PROVIDER, OR ADVERTISER, AND ANY DISPUTE YOU HAVE WITH ANY CARRIER, SERVICE PROVIDER, ADVERTISER, OR OTHER THIRD PARTY, INCLUDING ANY OTHER USER OF ANY PLATFORM, IS DIRECTLY BETWEEN YOU AND SUCH THIRD PARTY, AND YOU IRREVOCABLY RELEASE MYTHOS MAKERS (AND OUR OFFICERS, DIRECTORS, AGENTS, SUBSIDIARIES, JOINT VENTURES AND EMPLOYEES) FROM CLAIMS, DEMANDS AND DAMAGES (ACTUAL AND CONSEQUENTIAL) OF EVERY KIND AND NATURE, KNOWN AND UNKNOWN, ARISING OUT OF OR IN ANY WAY CONNECTED WITH SUCH DISPUTES. IF YOU ARE A CALIFORNIA RESIDENT, YOU WAIVE CALIFORNIA CIVIL CODE §1542, WHICH SAYS: “A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS FAVOR AT THE TIME OF EXECUTING THE RELEASE, WHICH IF KNOWN BY HIM MUST HAVE MATERIALLY AFFECTED HIS SETTLEMENT WITH THE DEBTOR.”
        </p>
        <h2>
          16. Miscellaneous
        </h2>
        <p>
          16.1. Your use of the Platform is subject to all additional terms, policies, rules, or guidelines applicable to the Platform or certain features of the Platform that we may post on or link to from any part of the Platform (the “Additional Terms”), such as additional license agreements for any downloadable Content, or rules that apply to particular features or content on the Platform, subject to Modification of this TOS. All Additional Terms are incorporated by this reference into, and made a part of, this TOS.
        </p>
        <p>
          16.2.  This TOS (which includes the Copyright Policy, the Privacy Policy, and, if applicable, any Additional Terms, and any Commercial Use Agreement between you and Mythos Makers (collectively, the “Ancillary Agreements”)) is the entire and exclusive understanding and agreement between you and Mythos Makers regarding your use of the Platform.
        </p>
        <p>
          16.3.  Except as expressly permitted in this TOS, this TOS may be amended only by a written agreement signed by authorized representatives of all parties to this TOS.
        </p>
        <p>
          16.4.  You may not assign or transfer this TOS or your rights under this TOS, in whole or in part, by operation of law or otherwise, without our prior written consent.
        </p>
        <p>
          16.5.  The failure to require performance of any provision will not affect our right to require performance at any other time after that, nor will a waiver by us of any breach or default of this TOS, or any provision of this TOS, be a waiver of any subsequent breach or default or a waiver of the provision itself.
        </p>
        <p>
          16.6.  For purposes of this TOS, (a) the words “include,” “includes” and “including” will be deemed to be followed by the words “without limitation”; (b) the words “such as”, “for example” “e.g.” and any derivatives of those words will mean by way of example and the items that follow these words will not be deemed an exhaustive list; (c) the word “or” is used in the inclusive sense of “and/or” and the terms “or,” “any,” and “either” are not exclusive; (d) the words “herein,” “hereof,” “hereby,” “hereto” and “hereunder” refer to this Agreement as a whole; (e) words denoting the singular have a comparable meaning when used in the plural, and vice-versa; and (f) whenever the context may require, any pronouns used in this Agreement shall include the corresponding masculine, feminine or neuter forms, and the singular form of nouns and pronouns shall include the plural, and vice versa. The headings set forth in this TOS are for convenience of reference purposes only and shall not affect or be deemed to affect in any way the meaning or interpretation of this Agreement or any term or provision hereof. Unless otherwise specified, references to “$” and “dollars'' are to the currency of the United States of America. Any law defined or referred to herein means such law as from time to time amended, modified or supplemented, including (in the case of statutes) by succession of comparable successor laws.
        </p>
        <p>
          16.7.  If any part of this TOS is held to be invalid or unenforceable, the unenforceable part will be given effect to the greatest extent possible, and the remaining parts will remain in full force and effect.
        </p>
    </div>
  )
}

export default TOS;