import '../stylesheets/header.css'
import mmlogo from '../images/MMLogo.png'
import hamburger from '../images/icons/hamburger.svg'
import userIcon from '../images/icons/user.svg'
import React, { useEffect, useState } from 'react';
import Cookies from "universal-cookie";
import { BrowserRouter, Route, Switch, useNavigate } from 'react-router-dom';
const cookies = new Cookies();


function Wrapper() {
  const [sideBarState, setSideBarState] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const userID = cookies.get("currentUserID")
  const userName = cookies.get("currentUsername")
  const [user, setUser] = useState(null) 
  const [isCreator, setIsCreator] = useState(false)
  const [loadedUserDate, setLoadedUserDate] = useState(false);

  const getUser = async (theUserId) => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_SERVER}/loginapi/getuser/${theUserId}`)
    .then(
      (response) => response.json({})
    )
    if(response){
      setUser(response)
      setIsCreator(response.isCreator)
      setIsLoggedIn(true)
    }
    
    setLoadedUserDate(true)
  }

  const logout = () => {
    cookies.remove("currentUserID", {path: "/"})
    cookies.remove("currentUsername", {path: "/"})
    setIsLoggedIn(false)
    setUser(null)
    setIsCreator(null)
    toggleSideBar()
  }

  const checkIsCreator = () => {
    if(user.isCreator == true){
      return(
        <a href="/dashboard" class="login">Creator Dashboard</a>
      )
    }
    
  }

  const checkIfLoggedIn = () => {
    if(loadedUserDate === true){
      if(isLoggedIn === false){
        return(
          <div class="sidebarContent lower">
          <a href="/login" class="login">Login</a>
          {/* <a href="/Signup" class="login">Signup</a> */}
          </div>
        )
      }else{
        return(
          <div class="sidebarContent lower">
            {checkIsCreator()}
            <a href="/user" class="login">{userName}</a>
            <a href="" onClick={logout} class="login">Logout</a>
          </div>
        )
      }
    }else{
      return("Loading")
    }
  }

  const sideBarShow = () =>{
    if(sideBarState === true){
      return(
        <div>
        <div class="sideBar">
          <img src={hamburger} alt="" class="sidebar-icon" onClick={ () => toggleSideBar()}/>
          <div class="sidebarContent">
            <a href="/">Home</a>
            <a href="/comics">Comics</a>
            <a href="/games">Games</a>
            <a href="/videos">Videos</a>
            <a href="/creators">Creators</a>
            {/* <a href="/events">Events</a> */}
          </div>
            {checkIfLoggedIn()}
        </div>
        <div class="sidebarFade" onClick={ () => toggleSideBar()} ></div>
        </div>
      )
    }
  }

  function toggleSideBar(){
    if(sideBarState === false){
      setSideBarState(true);
    }else{
      setSideBarState(false);
    }
  }
  
  useEffect(() => {
    if(userID != null){
      getUser(userID)
    }
    setLoadedUserDate(true)
  }, [cookies.get("currentUserID")])


  return (
    <div>
      <div>
        {sideBarShow()}
      </div>
      <div className="header">
        <img src={hamburger} alt="" class="header-icon" onClick={ () => toggleSideBar()}/>
        <a href="/" class="headerLogo">
          <img src={mmlogo} alt="Mythos Makers Logo" />
        </a>
        <div></div>
        {/* <img src={userIcon} alt="" class="header-icon"/> */}
      </div>
    </div>
  );
}

export default Wrapper;
