import React, { useEffect, useMemo, useState, useRef } from 'react';
import {
  BrowserRouter as Router,
  Link,
  Route,
  Routes,
  useParams,
} from "react-router-dom";
import {Helmet} from "react-helmet";
import Item from '../Item'
import '../../stylesheets/list.css'

function VideoSeries(){
  const [episodes, setEpisodes] = useState({});
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState('')
  const [headerImage, setHeaderImage] = useState(null)
  const [title, setTitle] = useState("")
  const [description, setDescription] = useState("")

  const [seasons, setSeasons] = useState([])

  const { series } = useParams()

  const getResultsData = async () => {
    if(isLoading == true){  
      getSeasons()
      const response = await fetch(`${process.env.REACT_APP_BACKEND_SERVER}/videos/bySeason/${series}/1`)
      .then((response) => response.json({}))
      setEpisodes(response)
      setLoading(false)
      // if(series == "sierra-bell"){
      //   setHeaderImage(sierraBellHeader)
      // }
      if(series == "johncoday"){
        //setHeaderImage(sierraBellHeader)
        setTitle("John Coday Videos")
        setDescription("A collection of many different videos John Coday has made over time of random things that just don't quite get there own Show!")
      }
      if(series == "frenchfrog"){
        //setHeaderImage(sierraBellHeader)
        setTitle("French Frog Videos")
        setDescription("Frog shares his adventures in Hunt Showdown and other games causing violence wherever he goes!")
      }
      if(series == "showerbrewcrew"){
        //setHeaderImage(sierraBellHeader)
        setTitle("The Shower Brew Cast")
        setDescription("The official podcast of the Shower Brew Crew. A group of creators who chronicle their adventures and lives in a comedic dynamic that you don't want to miss! Talking about popular culture, Twitch moments, and interviews with other creators! This podcast is the perfect thing if you enjoy the Twitch group, the SBC!")
      }
      if(series == "johnlikestotalk"){
        //setHeaderImage(sierraBellHeader)
        setTitle("John Likes To Talk")
        setDescription("Join in as John Chats with several people and strokes his ego farther than he already has!")
      }
    }
  };

  const resetResultsData = async (a) => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_SERVER}/videos/bySeason/${series}/${a}`)
      .then((response) => response.json({}))
      setEpisodes(response)
      setLoading(false)
  }

  const switchSeason = (e, a) => {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    resetResultsData(a)
  }

  const getSeasons = async () => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_SERVER}/videos/checkSeasons/${series}`)
    .then((response) => response.json({}))
    setSeasons(response)
  }

  useEffect(() => {
    getResultsData();
  });

  const seasonsStyle = {
    display: "flex",
    gap: "30px",
    color: "white",
    flexWrap: "wrap"
  }

  if (isLoading === true) return 'Loading...'
  if (error) return 'Error has occurred'
  return(
    
    <div class="contentList">
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
      <h1>
        {title}
      </h1>
      <h2 style={{width: "60%"}}>{description}</h2>
      <div style={seasonsStyle}>
        {seasons.map(num =>(
          <h2 >
            <a href="" class="seasonButton" onClick={(e) => switchSeason(e, num)}>
              Season {num}
            </a>
          </h2>
        ))}
      </div>
      <div class="contentList">
      {episodes.map(thing => (
        <Item title={thing["Title"]} icon={thing["Thumbnail"]} link={`/videos/${series}/${thing["ID"]}`} number={thing["Episode"]} season={thing["Season"]}></Item>
      ))}
      </div>
    </div>
  )

}
export default VideoSeries;