import React, { useState, useRef } from 'react';
import { BrowserRouter, Route, Switch, useNavigate } from 'react-router-dom';
import axios from 'axios'
import '../stylesheets/login.css'
import ReCAPTCHA from "react-google-recaptcha"
import Cookies from "universal-cookie";
const cookies = new Cookies();

function SignUp(){
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const captchaRef = useRef(null)
  const [isGood, setIsGood] = useState(true);
  const navigate = useNavigate();
  const [message, setMessage] = useState("")

  const handleSubmit = async (e) => {

    e.preventDefault();
    const token = captchaRef.current.getValue();
    captchaRef.current.reset();
    
    if(token){
      if(username && password && email && confirmPassword != "" && password == confirmPassword){

        if(checkString(username)){
          setIsGood(false)
        }
        if(checkString(password)){
          setIsGood(false)
        }
        if(checkString(email)){
          setIsGood(false)
        }
        
        if(isGood == true){
          const user = JSON.stringify({
            email: email,
            name: username,
            password: password,
            reCapToken: token
          })

          const request = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json; charset=utf-8' },
            url: `${process.env.REACT_APP_BACKEND_SERVER}/loginapi/signupuser`,
            data: user
          }

          axios(request)
          .then((result) => {
            cookies.set("currentUserID", result.data.ID, { path: "/",})
            cookies.set("currentUsername", username, { path: "/",})
            navigate("/")
          })
          .catch((result) => {
            setMessage(result.response.data.message)
          })
        }else{
          setMessage("Password and Confirm Password Not the Same")
        }

      }else{
        setMessage("Please Complete Form")
      }
    }else{
      setMessage("Recaptcha Not Complete")
    }
    
  }

  const checkString = (string) => {
    const setsOfString = "><?$"
    for(var i = 0; i < setsOfString.length; i++){
      if(string.includes(setsOfString[i])){
        return true
      }
    }
    return false
  }

  return(
    <div class="login">
      
    <form onSubmit={e => e.preventDefault()}>
      <label>
        <h2>{message}</h2>
      </label>
      <label>
        <h2>Email</h2>
        <input type="text" onChange={e => setEmail(e.target.value)}/>
      </label>
      <label>
        <h2>Username</h2>
        <input type="text" onChange={e => setUserName(e.target.value)}/>
      </label>
      <label>
        <h2>Password</h2>
        <input type="password" onChange={e => setPassword(e.target.value)}/>
      </label>
      <label>
        <h2>Confirm Password</h2>
        <input type="password" onChange={e => setConfirmPassword(e.target.value)}/>
      </label>
      <label>
        <ReCAPTCHA 
        sitekey="6Ldm9d8dAAAAAJRsqXUY7MGm7eTNMefcRh6ZsLLF"
        ref={captchaRef}
        />
      </label>
      <div>
        <br />
        <button onClick={handleSubmit}>Submit</button>
      </div>
    </form>
    </div>
  )
}

export default SignUp