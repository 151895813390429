import React, { useEffect, useState } from 'react';
import johnVertical from '../../images/verticals/John Coday.png'
import gardenVertical from '../../images/verticals/GardenWolf.png'
import dizzyVertical from '../../images/verticals/DizzyDummyBoy.png'
import zmVertical from '../../images/verticals/ZM_Art.png'
import marcyVertical from '../../images/Marcy.png'
import showerVertical from '../../images/verticals/ShowerBrewCrew.png'
import {Helmet} from "react-helmet";

const about_content = {
  margin: '80px'
}

function CreatorIndex(){
  return(
    <div style={about_content}>
      <Helmet>
        <title>Mythos Makers Creators</title>
        <meta name="description" content="The Creators that make everything at Mythos Makers" />
      </Helmet>
      <h1>Mythos Makers Creators</h1>
      <div className='blocks block-flex-wrap'>
        <a href="/creators/johncoday">
          <img src={johnVertical} className="vertical-thumbnail" ></img>
        </a>
        <a href="/creators/gardenwolf">
          <img src={gardenVertical} className="vertical-thumbnail" alt="" />
        </a>
        <a href="/creators/dizzydummy">
          <img src={dizzyVertical} className="vertical-thumbnail" alt="" />
        </a>
        <a href="/creators/zm">
          <img src={zmVertical} className="vertical-thumbnail" alt="" />
        </a>
        <a href="/creators/marcy">
          <img src={marcyVertical} className="vertical-thumbnail" alt="" />
        </a>
        <a href="/creators/showerbrewcrew">
          <img src={showerVertical} className="vertical-thumbnail" alt="" />
        </a>
      </div>
    </div>
  )
}

export default CreatorIndex;