import React, { useEffect, useState } from 'react';

const about_content = {
  margin: '80px'
}

function California() {
  return(
    <div style={about_content}>
      <h1>
      Your California Privacy Rights
      </h1>
      <p>
      Last Modified 1/20/22
      </p>
      <p>
      This notice to California residents is provided under California law, including the California Consumer Privacy Act (“CCPA”), Cal. Civ. Code 1798.100, et seq. This notice supplements our Privacy Policy by explaining your privacy rights if you are a California resident, providing our “notice at collection,” and providing certain mandated disclosures about our treatment of California residents’ information, both online and offline.
      </p>
      <h3>
      NOTICE OF COLLECTION OF PERSONAL INFORMATION
      </h3>
      <p>
      We collect these categories of personal information when you interact with us: identifiers/contact information, demographic information (such as gender and age), payment card information associated with you, geolocation data, audio, electronic, visual or similar information, and inferences drawn from the above.
      </p>
      <p>
      We collect this information so that we can best serve you, including to fulfill your requests and to share offers that we think you may be interested in.  A more detailed description of these purposes is in our Privacy Policy.
      </p>
      <p>
      California residents also have the right to “opt out” of the use of their personal information to third parties (as those terms are defined in the CCPA and described below).
      </p>
      <h3>
      CCPA MANDATORY DISCLOSURES
      </h3>
      <p>
      We also make the following disclosures for purposes of compliance with the CCPA:
      </p>
      <p>
      We collected the following categories of personal information in the last 12 months: identifiers/contact information, demographic information (such as gender and age), payment card information associated with you, geolocation data, audio, electronic, visual or similar information, and inferences drawn from the above.
      </p>
      <p>
      The sources of personal information from whom we collected are: directly from our guests, third-party sites or platforms that you link with your registration account, analytics tools, social networks, advertising networks, and third-party services that update or supplement information we hold about you.
      </p>
      <p>
      The business or commercial purposes of collecting personal information are as summarized in our “USE OF YOUR INFORMATION BY MYTHOS MAKERS” section, and as described in more detail in our Privacy Policy.
      </p>
      <p>
      We disclosed the following categories of personal information for a business purpose in the last 12 months: identifiers/contact information, demographic information (such as gender and age), payment card information associated with you, geolocation data, audio, electronic, visual or similar information, and inferences drawn from the above. We disclosed each category to third-party business partners and service providers, third-party sites or platforms such as social networking sites, and other third parties as described in the “SHARING YOUR INFORMATION WITH OTHER ENTITIES” section of the Privacy Policy.
      </p>
      <h3>
      YOUR RIGHTS
      </h3>
      <p>
      If you are a California resident, you have the right to request, up to two times each year, access to categories and specific pieces of personal information about you that we collect, use, disclose, and sell.
      </p>
      <p>
      If you are a California resident, you have the right to request that we delete personal information that we collect from you, subject to applicable legal exceptions.
      </p>
      <p>
      We are prohibited from selling infromation about our users to third party entities for any reason.
      </p>
      <h3>
      MAKING A CCPA REQUEST
      </h3>
      <p>
      Under our privacy section, you may submit a form to request access or deletion of your information. Before completing your request, we may need to verify your identity. We will send you a link to verify your email address and may request additional documentation or information solely for the purpose of verifying your identity.
      </p>
      <h3>
      SHINE THE LIGHT ACT
      </h3>
      <p>
      If you are a California resident, California Civil Code Section 1798.83 permits you to request information regarding the disclosure of your personal information by certain members of Mythos Makers to third parties for the third parties’ direct marketing purposes. We take great pride in the relationship of trust we have built with our guests over many years and we are dedicated to treating your personal information with care and respect. Pursuant to California Civil Code Section 1798.83(c)(2), members of Mythos Makers do not share guests’ personal information with other member companies or others outside Mythos Makers for those parties’ direct marketing use.
      </p>
      <h3>
      REMOVAL OF CONTENT
      </h3>
      <p>
      If you are a California resident under the age of 18, and a registered user of any site where this policy is posted, California Business and Professions Code Section 22581 permits you to request and obtain removal of content or information you have publicly posted.
      </p>
    </div>
  )
}

export default California;