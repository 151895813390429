import React, { useState, useRef } from 'react';
import DatePicker from "react-datepicker";
import Select from 'react-select'
import axios from 'axios'
import { useParams, useNavigate } from 'react-router-dom';

function AddComicEpisode(){
  const dragItem = useRef();
  const dragOverItem = useRef();
  const [list, setList] = useState([]);

  const [episode, setEpisode] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState('')
  const [title, setTitle] = useState('')
  const [format, setFormat] = useState('horizontal')
  const [premium, setPremium] = useState(false)
  const [dateAvailable, setDateAvailable] = useState()
  const [hidden, setHidden] = useState(true)
  const [episodeNumber, setEpisodeNumber] = useState(0)
  const [seasonNumber, setSeasonNumber] = useState(0)
  const [currentPart, setCurrentPart] = useState("Part 1")

  const navigate = useNavigate();

  const [episodeId, setEpisodeId] = useState("")

  const [newPages, setNewPages] = useState()

  const { series } = useParams()

  const uploadPages = () => {
    const formData = new FormData();
    formData.append('file', newPages);
    formData.append('episodeID', episodeId)
    const data = JSON.stringify({
      file : newPages,
      episodeID : episodeId
    })

    const request = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json; charset=utf-8' },
      url: `${process.env.REACT_APP_BACKEND_SERVER}/comic/uploadfiles`,
      data: formData
    }

    fetch(`${process.env.REACT_APP_BACKEND_SERVER}/comic/uploadfiles`,
			{
				method: 'POST',
				body: formData,
			}
		)
    .then((result) => {
      setLoading(true)
      reloadComicData()
    })
  }

  const getResultsData = async () => {
    if(isLoading){  
      const response = await fetch(`${process.env.REACT_APP_BACKEND_SERVER}/comic/${series}/${episodeId}`)
      .then((response) => response.json({}))
      .then((response) => {
        setEpisode(response)
        setLoading(false)
      })
      .catch((err) => {
        setError(err)
      });
      
    }
  };

  const reloadComicData = async () => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_SERVER}/comic/${series}/${episodeId}`)
    .then((response) => response.json({}))
    .then((response) => {
      setEpisode(response)
      setLoading(false)
    })
    .catch((err) => {
      setError(err)
    });
  }

  const handleChangeFile = (e) => {
    const img = {
      preview: URL.createObjectURL(e.target.files[0]),
      data: e.target.files[0],
    };
    setNewPages(e.target.files[0])
  }
 
  const dragStart = (e, position) => {
    dragItem.current = position;
  };
 
  const dragEnter = (e, position) => {
    dragOverItem.current = position;
  };
 
  const drop = (e) => {
    const copyListItems = [...list];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setList(copyListItems);
  };

  

  const nextPart = async () => {
    setLoading(true)
    const newID = generateId(12)

    const data = {
      title: title,
      premium: premium,
      episodeNumber: episodeNumber,
      seasonNumber: seasonNumber,
      format: format,
      newID: newID,
      series: series
    }

    const request = {
      method: "POST",
      headers: { 'Content-Type': 'application/json; charset=utf-8' },
      url: `${process.env.REACT_APP_BACKEND_SERVER}/comic/newEpisode`,
      data: data
    }

    axios(request)
    .then((result) => {
      navigate(`/dashboard/comics/${series}/editepisode/${newID}`)

    })
  }

  function generateId (length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }

  const options = [
    { value: 'horizontal', label: 'horizontal' },
    { value: 'vertical', label: 'vertical' }
  ]
 
  if (error) return `${error}`
  if (isLoading === true) return "Loading"
  if (currentPart === "Part 2"){
    return (
      <>
        <h2>
          Add Episode
        </h2>
        <div class="textArea">
        <div>Title</div>
        <input type="text" onChange={(e) => setTitle(e.target.value)} value={title} />
        <div>Premium</div>
        <input type="checkbox" onChange={(e) => setPremium(!premium)} class="checkbox" checked={premium} />
        <div>Hidden</div>
        <input type="checkbox" class="checkbox" onChange={(e) => setHidden(!hidden)} checked={hidden} />
        <div>Format</div>
        <select onChange={(e) => setFormat(e.target.value)} value={format}>
          <option value="horizontal">Horizontal</option>
          <option value="vertical">Vertical</option>
        </select>
        <div>Episode Number</div>
        <input type="number" onChange={(e) => setEpisodeNumber(e.target.value)} value={episodeNumber} />
        <div>Season Number</div>
        <input type="number" onChange={(e) => setSeasonNumber(e.target.value)} value={seasonNumber} />
        <h1>Add Pages</h1>
        <input  type="file" name="file" files={newPages} onChange={handleChangeFile}/>
        <button onClick={uploadPages}>Upload</button>
      </div>
      <div class="editSection">
        {
        list&&
        list.map((item, index) => (
          <div style={{margin:'20px', textAlign:'center', fontSize:'25px'}}
            onDragStart={(e) => dragStart(e, index)}
            onDragEnter={(e) => dragEnter(e, index)}
            onDragEnd={drop}
            key={index}
            draggable>
              <img src={`https://storage.googleapis.com/mythosmakers_storage/${item}`} alt="" class="horizontalPageEdit" />
              
          </div>
          ))}
          
        </div>
      </>
    )
  }
  return (
    <>
    <h2>Add Episode</h2>
    <div class="textArea">
      <div>Title</div>
      <input type="text" onChange={(e) => setTitle(e.target.value)} value={title} />
      <div>Premium</div>
      <input type="checkbox" onChange={(e) => setPremium(!premium)} class="checkbox" checked={premium} />
      <div>Format</div>
      <select onChange={(e) => setFormat(e.target.value)} value={format}>
        <option value="horizontal">Horizontal</option>
        <option value="vertical">Vertical</option>
      </select>
      <div>Episode Number</div>
      <input type="number" onChange={(e) => setEpisodeNumber(e.target.value)} value={episodeNumber} />
      <div>Season Number</div>
      <input type="number" onChange={(e) => setSeasonNumber(e.target.value)} value={seasonNumber} />
    </div>
    <button onClick={nextPart}>Next Part</button>
    </>
  );
};

export default AddComicEpisode;