import React, {useEffect, useState} from 'react';
import '../stylesheets/copyright.css'

const about_content = {
  margin: '80px'
}

function Copyright(){
  return(
    <div style={about_content}>
      <h1>
        Copyright + Content Use
      </h1>
      <h3>
        In regards to Mythos Makers related content, we want to make sure that our content is able to be watched by people whilst still being able to stastain ourselves to make more content.
      </h3>
      <h3>
        Because of this, we will use copyright laws in order to make sure our content isn't being used in ways that will hinder us from being able to continue making content.
      </h3>
      <h3>
        Depending on the size of the creator and the severity of the action will depend on what actions we take against the creator. For example, if the creator has 100 subscriber, and does a good reaction of a video, they may be completly ignored. If a creator with 1 million subs does a poor reaction of a video, we may either completly claim or strike the video.
      </h3>
      <h3>
        Please refer to the four pillars of fair use for a better understanding <a href="https://www.copyright.gov/fair-use/">HERE</a>.
      </h3>
      <h3>
        Here are a few rules regarding the use of our content for reproduction, reactions, etc.
      </h3>
      <br></br>
      <br></br>
      <h2>
        1. Reproduction / Reuploading
      </h2>
      <h3>
        ReUploading any form of Mythos Makers content in full is strictly prohibited.
      </h3>
      <h3>
        Uploading of smaller clips of smaller form content (for example, John Coday VRChat videos, John Coday Gameplay stream highlights, etc.) are allowed.
      </h3>
      <h3> 
        Clips of Mythos Makers Content as part of a larger form Video Essays is allowed.
      </h3>
      <h3>
        Re animating or fan works of Mythos Makers productions are allowed and encouraged.
      </h3>
      <h3>
        Fan dubs of Mythos Makers Comics are allowed.
      </h3>
      <h3>
        Gameplay videos of Mythos Makers Games are allowed and encouraged.
      </h3>
      <h3>

      </h3>
      <br></br>
      <br></br>
      <h2>
        2. Reaction Content
      </h2>
      <h3>
        We will be pretty strict with what content is allowed and in what forms it can be reacted by.
      </h3>
      <h3>
        For example, whilst smaller forms of content (including John Coday VRChat videos, John Coday Gameplay stream highlights, etc.) may not be as strict, larger forms of content (including Sierra Bell: The Magic's Curse, The Fox in the Wasteland, etc.) will have more stricter rules.
      </h3>
      <h3>
        We also push for transformative reactions of our content. A good example is <a href="https://www.corridordigital.com/">Corridor Digitals VFX Artist Reacts Videos</a>.
      </h3>
      <h3>
        Reacting to short clips of content are allowed as long as they are transformative.
      </h3>
      <h3>
        Reactions to larger amounts of content (whole episodes, larger amounts) is prohibited unless given written permission from a Mythos Makers Creative Director (John Coday, GardenWolf, ZM_Art) and must stay within the confins of said agreement.
      </h3>
      <br></br>
      <br></br>
      <h2>
        3. Use of Music
      </h2>
      <h3>
        Full reupload of Mythos Makers Music of anyform is strictly prohibited.
      </h3>
      <h3>
        Use of Mythos Makers Music as background music is dependant on the terms of the creator of the music.
      </h3>
      <h3>
        Use of Mythos Makers Games Music is allowed in video form content as background music.
      </h3>
      <br></br>
      <br></br>
      <h2>
        4. AI and Large Language Models
      </h2>
      <h3>
        Use of our content in any form of AI and Large Language Models is strictly prohibited and we hope you die in a fire!
      </h3>
    </div>
  )
}

export default Copyright;