import React, { useEffect, useState } from 'react';
import mmlogo from '../images/MMLogo.png'

const img = {
  marginBottom: '30px',
  textAlign: 'center',
  width: '600px'
}

const link = {
  color: 'white',
  textDecoration: 'none',
  marginLeft: '6px',
  marginRight: '6px',
  fontWeight: 'bolder'
}

const about_content = {
  margin: '80px'
}

function About() {
  return(
    <div style={about_content}>
      <a href="/" class="headerLogo">
        <img src={mmlogo} alt="Mythos Makers Logo" style={img} />
      </a>
      <h1>
        About Mythos Makers
      </h1>
      <p>
        Mythos Makers is a general entertainment and online media content creation site featuring several storytellers and creators with several backgrounds including Comic Writing and Drawing, General Writing, Video Game Development, Video Creation, Podcast Creation, and so on so forth.
      </p>
      <p>
        We are pushing the boundries of what is storytelling and creativity and finding any way to support not only creators who publish on our site but also creators on other platforms and fields.
      </p>
      <p>
        We believe that storytelling and creativity can cause great impacts on people and we want to do our best to create not only positive but lasting impacts on our viewers who enjoy what we create.
      </p>
      <h3>
        What is included in Mythos Makers?
      </h3>
      <p>
        We offer several Comic Series, Video Games, Video Shows, and Podcasts episodes coming from multiple different creators and voices from all over.
      </p>
      <h3>
        Can I give donations or subscribe to Mythos Makers or any of there creators?
      </h3>
      <p>
        Currently not through Mythos Makers itself, however we are working on making this an option for users to be able to support Mythos Makers and it's creators.
      </p>
      <p>
        In terms of payment safety for our users, Mythos Makers, John Coday, and other parties will use services including Stripe and Paypal to process transactions and will not have access to cetain private information incuding credit card numbers, bank information, and passwords. We will have information regarding certain transactions of physical items that are shipped to customers. Please refer to our 
      <a href='/privacypolicy' style={link}>
        Privacy Policy 
      </a>
        for more information.
      </p>
      <p>
        Information on cuts creators get vs what Mythos Makers gets will be shared on permission of the creators so that users know how much the creator earns by supporters.
      </p>
      <h3>
        Can I become a Creator on Mythos Makers?
      </h3>
      <p>
        Currently Creators are selected by John Coday and are only able to join on invitation by John.
      </p>
      <p>
        This does not mean that you cannot present an idea to John for an opporitunity to join. Just know that there is a big chance that the answer is no.
      </p>
      <p>
        The best way to do this is by seeing John at an event like conventions or in situations where you have contact with John.
      </p>
      <p>
        Do not use contact forms, emails, or DMs to ask for an opporitunity, you will get a higher chance of a no than before.
      </p>
    </div>
  )
}

export default About;