import '../../stylesheets/creatorDashboard.css'
import React, { useEffect, useState } from 'react';
import Cookies from "universal-cookie";
import { BrowserRouter, redirect, Route, Switch, useParams, useNavigate } from 'react-router-dom';
import AWS from 'aws-sdk';
import axios from 'axios';

function AddVideoEpisode(){

  const myBucket = new AWS.S3({
    params: { Bucket: "mythosmakers"},
    region: "us-west-1",
  })

  const [episode, setEpisode] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState('')
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [episodeNumber, setEpisodeNumber] = useState(0)
  const [seasonNumber, setSeasonNumber] = useState(0)
  const [hidden, setHidden] = useState(true)
  const [premium, setPremium] = useState(false)
  const [thumbnail, setThumbnail] = useState()
  const [fourK, setFourK] = useState(null)
  const [twoK, setTwoK] = useState(null)
  const [ten, setTen] = useState(null)
  const [seven, setSeven] = useState(null)
  const [four, setFour] = useState(null)
  const [three, setThree] = useState(null)
  const [two, setTwo] = useState(null)
  const [one, setOne] = useState(null)

  const { series} = useParams()

  const navigate = useNavigate();

  const [episodeId, setEpisodeId] = useState("")

  const [progress , setProgress] = useState(0);
  const [showProgress, setShowProgress] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  function generateId (length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }

  const uploadFileFunc = async (file) => {
    try {
      if (!file) {
        throw new Error('Select a file first!');
      }
      const formData = new FormData();
      formData.append('file', file[0]);
      await axios.post(`${process.env.REACT_APP_BACKEND_SERVER}/videos/${series}/uploadVideo`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      // handle success
    } catch (error) {
      // handle error
    }

    // const params = {
    //   ACL: 'public-read',
    //   Body: file,
    //   Bucket: "mythosmakers",
    //   Key: file.name
    // };

    // myBucket.putObject(params)
    //   .on('httpUploadProgress', (evt) => {
    //     setProgress(Math.round((evt.loaded / evt.total) * 100))
    //     console.log(Math.round((evt.loaded / evt.total) * 100))
    //   })
    //   .send((err) => {
    //     if (err) console.log(err)
    //   })
  }

  const submitForm = async () => {
    const formData = new FormData();
    formData.append('thumbnail', thumbnail)
    // formData.append('4K', fourK)
    // formData.append('2K', twoK)
    // formData.append('1080p', ten)
    // formData.append('720p', seven)
    // formData.append('480p', four)
    // formData.append('360p', three)
    // formData.append('240p', two)
    // formData.append('144p', one)
    formData.append('series', series)
    formData.append('title', title)
    formData.append('description', description)
    formData.append('premium', premium)
    formData.append('episodeNumber', episodeNumber)
    formData.append('seasonNumber', seasonNumber)
    const newID = generateId(12)
    formData.append('ID', newID)

    if(fourK != null){
      uploadFileFunc(fourK)
    }
    if(twoK != null){
      uploadFileFunc(twoK)
    }
    if(ten != null){
      uploadFileFunc(ten)
    }
    if(seven != null){
      uploadFileFunc(seven)
    }
    if(four != null){
      uploadFileFunc(four)
    }
    if(three != null){
      uploadFileFunc(three)
    }
    if(two != null){
      uploadFileFunc(two)
    }
    if(one != null){
      uploadFileFunc(one)
    }

    // fetch(`${process.env.REACT_APP_BACKEND_SERVER}/videos/${series}/new`,
		// 	{
		// 		method: 'POST',
		// 		body: formData
		// 	}
		// )
    // .then((result) => {
    //   console.log(result)
    // })
  }

  return(
    <>
    <h2>Add Episode</h2>
    <div class="textArea">
      <div>Title</div>
      <input type="text" onChange={(e) => setTitle(e.target.value)} value={title} />
      <div>Description</div>
      <textarea name="description" onChange={(e) => setDescription(e.target.value)} cols="30" rows="10">{description}</textarea>
      <div>Premium</div>
      <input type="checkbox" onChange={(e) => setPremium(!premium)} class="checkbox" checked={premium} />
      <div>Episode Number</div>
      <input type="number" onChange={(e) => setEpisodeNumber(e.target.value)} value={episodeNumber} />
      <div>Season Number</div>
      <input type="number" onChange={(e) => setSeasonNumber(e.target.value)} value={seasonNumber} />
      <div>Thumbnail</div>
      <input type="file" name="file" files={thumbnail} onChange={(e) => setThumbnail(e.target.files[0])}></input>
      <div>4K</div>
      <input type="file" name="4k" files={fourK} onChange={(e) => setFourK(e.target.files[0])} />
      <div>2K</div>
      <input type="file" name="2k" files={twoK} onChange={(e) => setTwoK(e.target.files[0])} />
      <div>1080p</div>
      <input type="file" name="ten" files={ten} onChange={(e) => setTen(e.target.files[0])} />
      <div>720p</div>
      <input type="file" name="seven" files={seven} onChange={(e) => setSeven(e.target.files[0])} />
      <div>480p</div>
      <input type="file" name="four" files={four} onChange={(e) => setFour(e.target.files[0])} />
      <div>360p</div>
      <input type="file" name="three" files={three} onChange={(e) => setThree(e.target.files[0])} />
      <div>240p</div>
      <input type="file" name="two" files={two} onChange={(e) => setTwo(e.target.files[0])} />
      <div>144p</div>
      <input type="file" name="one" files={one} onChange={(e) => setOne(e.target.files[0])} />
      <br />
      <br />
      <button onClick={submitForm}> Submit </button>
    </div>
    </>
  )

}

export default AddVideoEpisode;