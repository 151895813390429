import React, { useEffect, useMemo, useState, useRef } from 'react';
import shenanigans from '../../images/thumbnails/shenanigans.png'
import dmn7 from '../../images/thumbnails/dmn7.png'
import labrat from '../../images/thumbnails/labrat.png'
import {Helmet} from "react-helmet";

function AllGames(){


  return(
    <div>
      <Helmet>
        <title>Mythos Makers - Games</title>
        <meta name="description" content="All of Mythos Makers Games" />
      </Helmet>
      <a href="/games/shenanigans"><img src={shenanigans} alt="" class="thumbnail" /></a>
      <a href="/games/dmn7"><img src={dmn7} alt="" class="thumbnail" /></a>
      <a href="/games/labrat"><img src={labrat} alt="" class="thumbnail" /></a>
    </div>
  )
}
export default AllGames;