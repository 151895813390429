import React, { useEffect, useState } from 'react';
import sierraVertical from '../../images/verticals/Sierra Bell.png'
import valkyrieVertical from '../../images/verticals/Project Valkyrie.png'
import shenanigansVertical from '../../images/verticals/Shenanigans.png'
import johnVertical from '../../images/verticals/John Coday.png'
import gardenVertical from '../../images/verticals/GardenWolf.png'
import dizzyVertical from '../../images/verticals/DizzyDummyBoy.png'
import zmVertical from '../../images/verticals/ZM_Art.png'
import sierrabell from '../../images/thumbnails/sierrabell.png'
import valkyrie from '../../images/thumbnails/valkyrie.png'
import kaikaku from '../../images/thumbnails/kaikaku.png'
import shenanigans from '../../images/thumbnails/shenanigans.png'
import dmn7 from '../../images/thumbnails/dmn7.png'
import labrat from '../../images/thumbnails/labrat.png'
import johncrandom from '../../images/thumbnails/johncrandom.png'
import emblem from '../../images/Dizzy.png'
import {Helmet} from "react-helmet";

const about_content = {
  margin: '80px'
}

const emblem_style = {
  width: '20%'
}



function Dizzy(){
  return(
    <div style={about_content}>
      <Helmet>
        <title>Dizzy</title>
        <meta name="description" content="Dizzy Dummy" />
      </Helmet>
      <h1>Dizzy Dummy</h1>
      <h2>
        <img src={emblem} alt="logo" style={emblem_style} />
      </h2>
      <h2>
        The Right Hand Man of John who helps voice act in projects and goes on VRChat adventures with John. Dizzy voice acts for several projects in Mythos Makers as well as outside projects.
      </h2>
      <h1>Projects Dizzy is in!</h1>
      <div className="blocks">
        <a href="/games/shenanigans"><img src={shenanigans} alt="" class="thumbnail" /></a>
        <a href="/games/dmn7"><img src={dmn7} alt="" class="thumbnail" /></a>
      </div>
    </div>
  )
}

export default Dizzy;